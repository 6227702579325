import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Link } from 'react-router-dom'

import {
  depositIntentionRequested,
  confirmWithdrawalIntentionRequest,
  addDialog
} from '../../../redux/actions'

const getFormValuesToDeposit = values => ({
  amount: values.amount,
  operation_type: 'TED',
  currency: values.destination.currency,

  exch_addr: values.destination.address,
  user_addr: values.userBankAccount.address,

  agency: values.agency,
  account: values.account
})

const getFormValuesToWithdrawal = values => ({
  address: values.destination.address,
  amount: values.amount,
  currency: values.destination.currency,
  blockchain: values.blockchain,
  extraData: {
    bankName: values.destination.bankName,
  }
})

const mapDispatchToProps = dispatch => ({
  depositIntention: payload =>
    dispatch(depositIntentionRequested(getFormValuesToDeposit(payload))),
  withdrawalIntention: payload =>
    dispatch(confirmWithdrawalIntentionRequest(getFormValuesToWithdrawal(payload))),
  alertBSCSelected: (setNetwork) => {
    dispatch( addDialog({
        title: 'info.infoNetworkBSCSelectedTitle',
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        renderComponent: 'alertBSCSelected',
        availableChoices: [
          {
            label: 'common.cancel',
            actionToTake: 'setNetworkToBTC',
          },
          {
            label: 'common.ok',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised'
          },
        ],
        actionsToTake: {
          setNetworkToBTC: () => setNetwork(''),
        },
      })
    )},
  alertUserAboutTaxIdMissing: () => {
    dispatch(
      addDialog({
        title: 'transactions.withdrawal.missingTaxId',
        availableChoices: [
          {
            label: 'common.close',
            actionToTake: '',
            component: Link,
            to: '/profile/account',
            color: 'secondary',
            variant: 'raised'
          }
        ]
      })
    )
  },
    alertCBRLEndingSupport: () => {
        dispatch(
            addDialog({
                title: 'transactions.withdrawal.alertCBRLEndingSupport',
                availableChoices: [
                    {
                        label: 'common.close',
                        actionToTake: '',
                        color: 'secondary',
                        variant: 'raised'
                    }
                ]
            })
        )
    },
})

export default compose(
  connect(null, mapDispatchToProps)
)
