
import { FETCH_REFERRALS, FETCH_REFERRER_CONF } from "./actionsTypes";

const fetchReferrals = () => ({
  type: FETCH_REFERRALS,
});

const fetchReferrerConf = () => ({
  type: FETCH_REFERRER_CONF
})

export { fetchReferrals, fetchReferrerConf };