import React from "react";
import { Typography } from "@material-ui/core";
import StoreLinks from "../../../../../components/Buttons/StoreLinks";

export const PinRequiredMessage = ({ classes, width, t }) => {
    return (
      <>
       <Typography className={classes.warningText} variant="body2">
          {`• ${t("info.pinIsRequiredToWithdrawal")}`}
        </Typography>
        <Typography className={classes.warningText} variant="body2">
          {`• ${t("info.withdrawalPinVerificationNotice")}`}
        </Typography>
        <Typography className={classes.warningText} variant="body2">
          {`• ${t("info.downloadOurApp")}`}
        </Typography>
        <div className={classes.storeLinksContainer}>
          <StoreLinks
            size={2.5}
            width={"100%"}
            flexDirection={width <= 500 ? "column" : "row"}
          />
        </div>
      </>
    );
  };