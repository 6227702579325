import React from 'react'
import OrderBookOrderTableRow from './OrderBookOrderTableRow'

const OrderBookTableRow = props => {
  const {
    order,
    ordersType,
    copyRow,
    confirmCancelOrder,
    isShowAllSelected,
    t,
    classes,
    marketConfig,
    currency,
  } = props

  return (<OrderBookOrderTableRow
        t={t}
        key={`OrderBookOrderTableRow__${order.id}`}
        order={order}
        ordersType={ordersType}
        classes={classes}
        copyRow={copyRow}
        isShowAllSelected={isShowAllSelected}
        confirmCancelOrder={confirmCancelOrder}
        currency={currency}
        marketConfig={marketConfig}
      />)
}

export default React.memo(OrderBookTableRow)
