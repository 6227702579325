import { put, takeLatest, select } from "redux-saga/effects";
import * as R from "ramda";
import { FETCH_MESSAGE_SUCCEED } from "./actionTypes";
import { fetchPrivateData } from "../actions";
import { updateDataByType } from "../../actions";
import { addSnack } from "../../layout/actions";
import beep from "../../../layout/beep";
import { gtmTransaction } from "../../../utils/dataLayer";
import { fetchExtract } from "../../user/extract/actions";
import {
  serviceUpdateTypes,
  throttleMSInterval,
  typeRequestTimeToLive,
  bityPhoenixNotifications,
} from "../../../utils/constants";
import { dataRequestCacheController } from "../../../config";

const ordersToBeep = ["ORDER_FULLY_EXECUTED", "ORDER_PARTIALLY_EXECUTED"];
const depositMessages = ["FIAT_DEPOSIT_COMPLETE", "COINS_DEPOSIT_COMPLETE"];
const ignoredMessages = [bityPhoenixNotifications.RECEIVING_COINS_DEPOSIT, bityPhoenixNotifications.COINS_WITHDRAWAL_APPROVED]

function* fetchMessageSaga({ response }) {
  try {
    let updates = [];

    const message_cod = response.type || response.message_cod;
    const message_to = undefined;
    const isAOrderToBeep = R.contains(message_cod, ordersToBeep);
    const {
      layout: {
        appBar: { hasSound },
      },
    } = yield select(R.identity);

    if (message_cod && dataRequestCacheController.cacheHasExpired({ identifier: message_cod, ttl: typeRequestTimeToLive[message_cod] ?? throttleMSInterval })) {
      const shouldBeep = hasSound && (message_to === "USER" || isAOrderToBeep);
      if (shouldBeep) {
        beep();
      }
      if (R.contains(message_cod, depositMessages)) {
        gtmTransaction("DEPOSIT");
      }

      yield put(addSnack({ message: message_cod }));
      if (
        R.contains(message_cod, [
          /// transactions
          bityPhoenixNotifications.FIAT_DEPOSIT_COMPLETE,
          bityPhoenixNotifications.COINS_DEPOSIT_COMPLETE,
          bityPhoenixNotifications.BALANCE_DEDUCTION,
          bityPhoenixNotifications.FIAT_WITHDRAWAL_COMPLETE,
          bityPhoenixNotifications.TRANSACTION_CANCELED,
          bityPhoenixNotifications.FIAT_WITHDRAWAL_CANCELED,
          bityPhoenixNotifications.COINS_WITHDRAWAL_CANCELED,
          bityPhoenixNotifications.COINS_DEPOSIT_COMPLETE,
          bityPhoenixNotifications.COINS_WITHDRAWAL_COMPLETE,

          /// orders
          bityPhoenixNotifications.ORDER_FULLY_EXECUTED,
          bityPhoenixNotifications.ORDER_PARTIALLY_EXECUTED,

          /// pre-paid card notifications
          bityPhoenixNotifications.PAYMENT_CONCLUDED,
          bityPhoenixNotifications.PAYMENT_CANCELED,
          bityPhoenixNotifications.REFUND_CONCLUDED,
          bityPhoenixNotifications.CASHBACK_RECEIVED,
        ])
      ) {
        updates.push(serviceUpdateTypes.TRANSACTIONS);
      }
      if (
        R.contains(message_cod, [
          bityPhoenixNotifications.ORDER_FULLY_EXECUTED,
          bityPhoenixNotifications.ORDER_PARTIALLY_EXECUTED,
          bityPhoenixNotifications.BUY_ORDER_CREATED,
          bityPhoenixNotifications.SELL_ORDER_CREATED,
        ])
      ) {
        updates.push(serviceUpdateTypes.ORDER);
      }
      if (
        R.contains(message_cod, [
          bityPhoenixNotifications.ORDER_FULLY_EXECUTED,
          bityPhoenixNotifications.ORDER_PARTIALLY_EXECUTED,
        ])
      ) {
        updates.push(serviceUpdateTypes.EXECUTED_ORDERS);
      } else if (
        R.contains(message_cod, [
          bityPhoenixNotifications.BUY_ORDER_CREATED,
          bityPhoenixNotifications.SELL_ORDER_CREATED,
          bityPhoenixNotifications.ORDER_CANCELED,
        ])
      ) {
        updates.push(serviceUpdateTypes.BALANCE);
      } else if (
        R.contains(message_cod, [
          bityPhoenixNotifications.KYC_APPROVED,
          bityPhoenixNotifications.USER_PROFILE_UPDATED,
        ])
      ) {
        updates.push(serviceUpdateTypes.USER);
      } else if (
        R.contains(message_cod, [bityPhoenixNotifications.CARDS_UPDATED])
      ) {
        updates.push(serviceUpdateTypes.CARDS);
      }
      if (updates.length > 0) {
        yield put(updateDataByType({ updateTypes: updates }));
      } else if(!ignoredMessages.includes(message_cod)) {
        yield put(fetchPrivateData());
        yield put(fetchExtract());
      }
    }
  } catch (error) {
    console.error("fetchMessageSaga", error);
  }
}

function* watchMessage() {
  yield takeLatest(FETCH_MESSAGE_SUCCEED, fetchMessageSaga);
}

export default watchMessage;
