import {
  put, takeLatest, takeEvery, call, select,
} from 'redux-saga/effects';
import * as R from 'ramda';
import actions from './actions';
import { CREATE_ORDER, CANCEL_ORDER, INIT_CANCEL_ORDER } from './actionTypes';
import fetchBuy from '../../services/cbtcService/buy';
import fetchSell from '../../services/cbtcService/sell';
import fetchCancelOrder from '../../services/cbtcService/cancelOrder';
import beep from '../../layout/beep';
import { getMarketOrderSerialized } from '../../utils/generalUtils';

import {
  addSnack,
  addDialog,
  optimisticAddOrder,
  optimisticCancelOrder,
  optimisticRemoveOrder,
  addSessionExpiredDialog,
  fetchOrdersRequested,
  fetchBalance,
  updateOrders,
} from '../actions';
import { gtmTransaction } from "../../utils/dataLayer";
import { bityPhoenixNotifications, serviceUpdateTypes, tradeTypes, typeRequestTimeToLive } from '../../utils/constants';
import { dataRequestCacheController, triggerTradeEvent } from '../../config';
import { updateDataAndCacheRequest } from '../../utils/sagaUtils';

function* handleSuccessOrderCreated({
  typeRequest,
  updateTypes,
  type,
  order,
  cbRes,
}) {
  const isOrderFullyExecuted =
    typeRequest === bityPhoenixNotifications.ORDER_FULLY_EXECUTED;
  const ordersToBeep = [
    bityPhoenixNotifications.ORDER_FULLY_EXECUTED,
    bityPhoenixNotifications.ORDER_PARTIALLY_EXECUTED,
  ];

  const hasSound = yield select((state) => state.layout.appBar.hasSound);

  yield updateDataAndCacheRequest({
    typeRequest,
    updateTypes,
  });

  if (typeRequest) {
    yield put(addSnack({ message: typeRequest }));
  }

  if (ordersToBeep.includes(typeRequest) && hasSound) {
    beep();
  }

  if (isOrderFullyExecuted) {
    yield handleOrderFullyExecuted(cbRes);
  } else {
    yield put(
      optimisticAddOrder({
        orderType: type,
        order,
      })
    );
  }
}

function* handleOrderFullyExecuted(cbRes) {
  const currentExecutedOrders = yield select(
    (state) => state.orders.executedOrders
  );
  const serializedOrder = getMarketOrderSerialized(cbRes);
  const newExecutedOrders = R.append(serializedOrder, currentExecutedOrders);
  yield put(
    updateOrders({
      executedOrders: newExecutedOrders,
    })
  );
}

function* createOrderSaga({ order: { id, type, ...order } }) {
  try {
    const isBuy = type === "BUY";
    const fetchCreateOrder = isBuy ? fetchBuy : fetchSell;
    order["source"] = tradeTypes.orderbook;
    const cbRes = yield call(() => fetchCreateOrder(order));

    const { success, message_cod, timestamp } = cbRes;

    if (success) {
      const parametersEvent = {
        isBuy: isBuy ? 1 : 0,
        price: cbRes?.price,
        amount: cbRes?.amount,
        market: cbRes?.market,
        limited: order?.limited == true ? 1 : 0,
      };
      triggerTradeEvent("trade", parametersEvent);
      gtmTransaction(type);

      let typeRequest = bityPhoenixNotifications[message_cod];
      const updateTypes = [];

      switch (message_cod) {
        case bityPhoenixNotifications.ORDER_FULLY_EXECUTED:
          updateTypes.push(serviceUpdateTypes.BALANCE);
          break;
        case "ORDER_CREATED":
          typeRequest = bityPhoenixNotifications[`${type}_ORDER_CREATED`];
          break;
      }

      if (updateTypes.length == 0) {
        updateTypes.push(serviceUpdateTypes.BALANCE);
        updateTypes.push(serviceUpdateTypes.ORDER);
      }

      yield handleSuccessOrderCreated({
        typeRequest,
        updateTypes,
        type,
        order: { time_stamp: timestamp, ...order },
        cbRes,
      });
    }
    yield put(actions.clearSelectedOrder());
  } catch (error) {
    const { message_cod } = error;
    if (message_cod === "INVALID_TOKEN") {
      yield put(addSessionExpiredDialog());
    } else {
      yield put(addSnack({ message: message_cod }));
    }
    yield put(fetchOrdersRequested());
    console.error("createOrderSaga", error);
  }
}


function* initCancelOrderSaga({ op, orders }) {
  const cancelOrdersActions = R.map(order => actions.cancelOrder({ op, ...order }));
  const title = R.length(orders) > 1
    ? `transactions.${op.toLowerCase()}.cancelMany`
    : `transactions.${op.toLowerCase()}.cancel`;

  try {
    yield put(addDialog({
      title,
      availableChoices: [
        {
          label: 'common.no',
          actionToTake: '',
          color: 'secondary',
          variant: 'raised',
        },
        {
          label: 'common.yes',
          actionToTake: 'cancel',
        },
      ],
      actionsToTake: {
        cancel: () => cancelOrdersActions(orders),
      },
    }));
  } catch (error) {
    console.error('endSessionSaga', error);
  }
}

function* handleSuccessCancelOrder({ op, order, message_cod }) {
  yield put(optimisticRemoveOrder({ orderType: op, order }));
  yield put(fetchBalance());
  yield put(addSnack({ message: message_cod }));
  const typeRequest = bityPhoenixNotifications.ORDER_CANCELED;
  dataRequestCacheController.cacheRequest({
    identifier: typeRequest,
    ttl: typeRequestTimeToLive[typeRequest],
  });
}

function* cancelOrderSaga({ order: { op = '', ...order } }) {
  try {
    const retriedOrders = yield select(state => state.transactions.retriedOrders);
    const isRetry = retriedOrders.includes(order.id);
    if (!isRetry) yield put(optimisticCancelOrder({ orderType: op, order }));
    const cbRes = yield call(() => fetchCancelOrder(order));

    const { success, message_cod } = cbRes; // eslint-disable-line camelcase
    if (success) {
      yield handleSuccessCancelOrder({ op, order, message_cod });
    } else if (message_cod === 'INVALID_TOKEN') { // eslint-disable-line camelcase
      yield put(addSessionExpiredDialog());

    } else {
      if (!isRetry) {
        yield put(actions.addRetryCancelOrder(order.id));
        yield put(actions.cancelOrder(order));
      }
      yield put(addSnack({ message: message_cod }));
    }
  } catch (error) {
    const { message_cod } = error; // eslint-disable-line camelcase
    if (message_cod === 'INVALID_TOKEN')
      yield put(addSessionExpiredDialog());
    else
      yield put(addSnack({ message: message_cod }));

    console.error('cancelOrderSaga', error);
  }
}

function* watchTransactions() {
  yield takeLatest(CREATE_ORDER, createOrderSaga);
  yield takeEvery(CANCEL_ORDER, cancelOrderSaga);

  yield takeLatest(INIT_CANCEL_ORDER, initCancelOrderSaga);

}

export default watchTransactions;
