const UPDATE_ORDERS = 'UPDATE_ORDERS';
const OPTIMISTIC_ADD_ORDER = 'OPTIMISTIC_ADD_ORDER';
const OPTIMISTIC_REMOVE_ORDER = 'OPTIMISTIC_REMOVE_ORDER';
const OPTIMISTIC_CANCEL_ORDER = 'OPTIMISTIC_CANCEL_ORDER';
const FETCH_ORDERS_REQUESTED = 'FETCH_ORDERS_REQUESTED';
const ENABLE_SYNC_ORDERS = 'ENABLE_SYNC_ORDERS';
const DISABLE_SYNC_ORDERS = 'DISABLE_SYNC_ORDERS';
const FETCH_EXECUTED_ORDERS_REQUESTED = 'FETCH_EXECUTED_ORDERS_REQUESTED'; 
const SET_USING_USER_EXECUTED_ORDERS = 'SET_USING_USER_EXECUTED_ORDERS';
const GET_QUOTE = 'GET_QUOTE';
const UPDATE_QUOTE = 'UPDATE_QUOTE' ;
const FAIL_ON_QUOTE = 'FAIL_ON_QUOTE' ;
const EXECUTE_QUOTE ='EXECUTE_QUOTE';
const HANDLE_SUCCESS_EXECUTE_QUOTE = 'HANDLE_SUCCESS_EXECUTE_QUOTE';

export {
  UPDATE_ORDERS,
  FETCH_ORDERS_REQUESTED,
  OPTIMISTIC_ADD_ORDER,
  OPTIMISTIC_CANCEL_ORDER,
  OPTIMISTIC_REMOVE_ORDER,
  FETCH_EXECUTED_ORDERS_REQUESTED,
  GET_QUOTE,
  EXECUTE_QUOTE,
  FAIL_ON_QUOTE,
  UPDATE_QUOTE,
  ENABLE_SYNC_ORDERS,
  DISABLE_SYNC_ORDERS,
  SET_USING_USER_EXECUTED_ORDERS,
  HANDLE_SUCCESS_EXECUTE_QUOTE,
};
