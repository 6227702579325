import * as R from 'ramda';
import * as actions from './actionTypes';

const initialState = {
  sync: false,
  extracts: [],
  form: {}
};

const fetchExtractSucceed = (state, { response }) => {
  const isEqual = R.equals(response.data, state.extracts)
  if (isEqual) {
    return state
  }
  return R.merge(state, { extracts: response.data });
};

const updateCurrentExtract = (state, { extract }) => {
  return R.merge(state, { extracts: [...extract, ...state.extracts] });
}

const replaceExtract = (state, { extract }) => {
  return R.merge(state, { extracts: extract });
}

const toogleSyncExtract = (state, { sync, form }) => {
  if ( form ){
    return R.merge(state, { sync, form });
  }
  return state;
};

const extractReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_EXTRACT:
      return toogleSyncExtract(state, action);
    case actions.ENABLE_SYNC_EXTRACT:
      return toogleSyncExtract(state, action);
    case actions.DISABLE_SYNC_EXTRACT:
      return toogleSyncExtract(state, action);
    case actions.UPDATE_EXTRACT:
      return updateCurrentExtract(state, action);
    case actions.REPLACE_EXTRACT:
      return replaceExtract(state, action);
    case 'FETCH_SERVICE_SUCCEED':
      switch (action.requestType) {
        case 'fetchExtract':

          return fetchExtractSucceed(state, action);

        default:
          return state;
      }

    default:
      return state;
  }
};

export default extractReducer;
