import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Card, ButtonBase } from "@material-ui/core";
import UnavailableFeatureDialog from "../../components/UnavailableFeatureDialog";
import { ImportExport } from "@material-ui/icons";
import { styles } from "./styles";
import { compose } from "recompose";
import {
  AvailableBalanceDisplay,
  CryptoConverterCard,
  FromVolumeAmountInput,
  ToAmountInput,
} from "./components";
import { Currency, getCurrencyBySymbol } from "../../models/Currency";
import withCryptoConverter from "./withCryptoConverter";
import ConfirmConvertionDialog from "../../layout/Dialogs/ConfirmConvertionDialog/ConfirmConvertionDialog";
import classNames from "classnames";
import CountDownSubmitButton from "../../components/Forms/CountDownSubmitButton";
import isNilOrEmpty from "../../utils/isNilOrEmpty";
import InfoCard from "../../components/Cards/InfoCard/InfoCard";
import KnowMoreLink from "../../components/Buttons/KnowMore/KnowMoreLink/KnowMoreLink";
import { FIREBASE_LINKS } from "../../config";
const CryptoConverter = (props) => {
  const {
    classes,
    demoAccount,
    anchorEl,
    setAnchorEl,
    t,
    currencies,
    balance,
    values,
    initialFromCurrencyData,
    initialToCurrencyData,
    brlCurrencyData,
    handleFromInputChange,
    handleToInputChange,
    handleSwitchFromAmountType,
    handleMaxButtonClick,
    isRequestingQuote,
    errors,
    resetAllForm,
    ticker,
    getConvertionQuoteWithLastValues,
    getConvertionQuote,
    cancelQuote,
    executeConvertionQuote,
    setSelectedMarket,
    market,
    setFieldValue,
    showConfirmConvertionDialog,
    setShowConfirmConvertionDialog,
  } = props;
  const debounceGetConvertionQuote = useCallback(
    debounce(async (requestData) => await getConvertionQuote(requestData), 900),
    [ticker]
  );
  const { quoteId, expiration } = values;
  const [fromCurrency, setFromCurrency] = useState(
    new Currency(initialFromCurrencyData)
  );
  const [toCurrency, setToCurrency] = useState(
    new Currency(initialToCurrencyData)
  );
  const [showWarningDemoAccount, setShowWarningDemoAccount] = useState(false);
  const brlCurrency = new Currency(brlCurrencyData);
  const canTrade =
    !demoAccount && !isRequestingQuote && isNilOrEmpty(errors) && quoteId;

  const handleSwitchCurrenciesClick = () => {
    cancelQuote();
    selectedFromCurrency(toCurrency);
  };

  const isToCurrencyWithdrawalDisabled = toCurrency.withdrawal.length === 0;

  const fromInput = (
    <FromVolumeAmountInput
      handleSwitchFromAmountType={() => {
        handleSwitchFromAmountType(debounceGetConvertionQuote);
      }}
      handleFromInputChange={(amount) => {
        handleFromInputChange(amount, debounceGetConvertionQuote);
      }}
      disabled={demoAccount}
      fromAmount={values.fromAmount}
      volumeAmount={values.volumeAmount}
      fromCurrency={fromCurrency}
      brlCurrency={brlCurrency}
      fromAmountType={values.fromAmountType}
    />
  );
  const toInput = (
    <ToAmountInput
      disabled={demoAccount}
      currency={toCurrency}
      value={values.toAmount}
      placeholder={`${toCurrency.monetarySymbol} `}
      handleToInputChange={(amount) => {
        handleToInputChange(amount, debounceGetConvertionQuote);
      }}
    />
  );

  const selectedFromCurrency = (currency) => {
    if (currency.symbol === fromCurrency.symbol) return;
    if (currency.symbol === toCurrency.symbol) {
      setFromCurrency(toCurrency);
      setToCurrency(fromCurrency);
      setFieldValue("fromCurrency", toCurrency.symbol);
      setFieldValue("toCurrency", fromCurrency.symbol);
    } else {
      setFromCurrency(new Currency(currency));
      setFieldValue("fromCurrency", currency.symbol);
    }
    resetAllForm();
  };

  const selectedToCurrency = (currency) => {
    if (currency.symbol === toCurrency.symbol) return;
    if (currency.symbol === fromCurrency.symbol) {
      setToCurrency(fromCurrency);
      setFromCurrency(toCurrency);
      setFieldValue("fromCurrency", toCurrency.symbol);
      setFieldValue("toCurrency", fromCurrency.symbol);
    } else {
      setToCurrency(new Currency(currency));
      setFieldValue("toCurrency", currency.symbol);
    }
    resetAllForm();
  };
  const coinsSelectorExtraData = (isFromCurrency) => ({
    hasStableCoin: false,
    customActionOnClick: isFromCurrency
      ? (currency) => {
          selectedFromCurrency(currency);
          if (currency.symbol !== fromCurrency.symbol) {
            setSelectedMarket({ market: `${currency.symbol}-BRL` });
          }
        }
      : selectedToCurrency,
    disableHighlight: true,
  });

  useEffect(() => {
    const symbol = market.split("-")[0];
    if (symbol !== fromCurrency.symbol) {
      const newCurrencyData = getCurrencyBySymbol(currencies, symbol);
      selectedFromCurrency(newCurrencyData);
    }
  }, [market]);
  
  return (
    <>
      <div className={classes.cryptoConverterContainer}>
        <form>
          <Grid container className={classes.transactionContainer}>
            <Grid justify="center" alignItems="center" item xs={12} md={12}>
              <Card
                className={demoAccount ? classes.cardFade : classes.card}
                onMouseEnter={
                  demoAccount
                    ? (event) => setShowWarningDemoAccount(true)
                    : null
                }
                onMouseLeave={
                  demoAccount
                    ? (event) => setShowWarningDemoAccount(false)
                    : null
                }
              >
                {isToCurrencyWithdrawalDisabled && (
                  <div
                    className={classNames(
                      classes.container,
                      classes.marginBottom10
                    )}
                    item
                  >
                    <InfoCard
                      content={`${t("info.notWithdrawalOrDepositWarning", {
                        token: toCurrency.symbol,
                      })}<br>${t("info.youCanBuyAndSellAnyTime")}`}
                    />
                  </div>
                )}
                {!demoAccount && (
                  <AvailableBalanceDisplay
                    onMaxButtonClick={() =>
                      handleMaxButtonClick(debounceGetConvertionQuote)
                    }
                    currencyPrefix={fromCurrency.monetarySymbol}
                    availableAmount={balance[fromCurrency.symbol]}
                  />
                )}
                {demoAccount && showWarningDemoAccount && (
                  <UnavailableFeatureDialog
                    featureType={"cryptoConverter"}
                    cardPlacement={classes.cardPlacement}
                  />
                )}
                <Grid container spacing={16} direction="column">
                  <Grid item>
                    <CryptoConverterCard
                      label={t("common.of")}
                      currency={fromCurrency}
                      input={fromInput}
                      handleCurrencySelector={
                        demoAccount
                          ? null
                          : (event) =>
                              anchorEl
                                ? setAnchorEl(null)
                                : setAnchorEl(
                                    event.currentTarget,
                                    true,
                                    coinsSelectorExtraData(true)
                                  )
                      }
                    />
                  </Grid>
                  <Grid item className={classes.switchButtonWrapper}>
                    <ButtonBase
                      className={classes.switchButton}
                      onClick={handleSwitchCurrenciesClick}
                    >
                      <ImportExport />
                    </ButtonBase>
                    <CryptoConverterCard
                      label={t("common.to")}
                      currency={toCurrency}
                      input={toInput}
                      handleCurrencySelector={
                        demoAccount
                          ? null
                          : (event) =>
                              anchorEl
                                ? setAnchorEl(null)
                                : setAnchorEl(
                                    event.currentTarget,
                                    true,
                                    coinsSelectorExtraData(false)
                                  )
                      }
                    />
                  </Grid>
                  <Grid item className={classes.maxWidthFitContent}>
                    <KnowMoreLink
                      text={t(
                        "screens.cryptoConverter.understandHowCryptoConverterWorks"
                      )}
                      urlLink={FIREBASE_LINKS.cryptoConverterHowUseArticle}
                    />
                  </Grid>
                  <Grid item>
                    <CountDownSubmitButton
                      onClick={() => setShowConfirmConvertionDialog(true)}
                      disabled={!canTrade}
                      isSubmitting={isRequestingQuote}
                      onTimeout={getConvertionQuoteWithLastValues}
                      className={classNames(
                        classes.submitButton,
                        classes.colorWhiteWithBold
                      )}
                      blockUserSubmit={demoAccount}
                      type="button"
                      fullWidth
                      id="converter_button"
                      timestamp={
                        !isRequestingQuote && expiration ? expiration : null
                      }
                      color="secondary"
                      submitText={t("screens.cryptoConverter.convert")}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </form>
      </div>
      {showConfirmConvertionDialog && (
        <ConfirmConvertionDialog
          canTrade={canTrade}
          isRequestingQuote={isRequestingQuote}
          getConvertionQuoteWithLastValues={getConvertionQuoteWithLastValues}
          open={showConfirmConvertionDialog}
          handleClose={() => setShowConfirmConvertionDialog(false)}
          handleSubmit={executeConvertionQuote}
          quoteValues={values}
          fromCurrency={fromCurrency}
          toCurrency={toCurrency}
          brlCurrency={brlCurrency}
        />
      )}
    </>
  );
};

const enhance = compose(withStyles(styles), withCryptoConverter);

export default enhance(CryptoConverter);
