import { put } from "redux-saga/effects";
import { updateDataByType } from "../redux/actions";
import { dataRequestCacheController } from "../config";

export function* updateDataAndCacheRequest({ updateTypes, typeRequest, ttl }) {
  yield put(
    updateDataByType({
      updateTypes,
    })
  );
  dataRequestCacheController.cacheRequest({
    identifier: typeRequest,
    ttl,
  });
}
