import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { compose } from 'recompose'
import { translate } from 'react-i18next'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import AppCard from '../../components/Cards/AppCard'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Toolbar from '@material-ui/core/Toolbar'

import transactionsActions from '../../redux/transactions/actions'
import TradeOrderForm from './TradeOrderForm/index'
import UnavailableFeatureDialog from '../../components/UnavailableFeatureDialog';
import InfoTooltip from '../../components/InfoTooltip';
import CurrencyIcon from '../../components/CurrencyIcon';
import { Currency, getCurrencyBySymbol } from '../../models/Currency';

const styles = theme => ({
  tabLabel: {
    fontSize: '1.1em',
    fontWeight: 500,
  },
  buyLabel: { color: theme.buy },
  sellLabel: { color: theme.sell },
  stoplossLabel: { display: 'flex', color: theme.stoploss, cursor: 'pointer' },
  cardHeaderClass: {
    backgroundColor: theme.buy,
  },
  fade:{
    '&:hover': {
      background: 'rgba(0,0,0,0.2)',
      zIndex: 1000,
    }
  },
})

const ORDER_TYPES = {
  BUY: 'BUY',
  SELL: 'SELL',
  STOPLOSS: 'STOPLOSS'
}

const mapStateToProps = (state) => ({
  selectedOrder: state.transactions.selectedOrder,
  userEmail: state.user.profile.email,
  demoAccount: state.credentials.demoAccount,
  currencies: state.currencies.currencies,
  markets: state.currencies.markets,
  market: state.market.selectedMarket,
});

const mapDispatchToProps = dispatch => ({
  clearSelectedOrder: () => dispatch(transactionsActions.clearSelectedOrder())
})

const getOrderTypeByTabIndex = index =>{
  switch (index) {
    case 0:
      return ORDER_TYPES.BUY
    case 1:
      return ORDER_TYPES.SELL
    case 2:
      return ORDER_TYPES.STOPLOSS
    default:
      return ORDER_TYPES.BUY
  }
}

const getSelectedTab = selectedOrder => {
  const BUY_ORDER_INDEX = 0
  const SELL_ORDER_INDEX = 1
  const STOP_LOSS_INDEX = 2

  switch (selectedOrder.orderType) {
    case ORDER_TYPES.BUY:
      return SELL_ORDER_INDEX
    case ORDER_TYPES.SELL:
      return BUY_ORDER_INDEX
    case ORDER_TYPES.STOPLOSS:
      return STOP_LOSS_INDEX
    default:
      return BUY_ORDER_INDEX
  }
}

const TradeOrderView = props => {
  const {
    t,
    classes,
    selectedOrder,
    clearSelectedOrder,
    marketConfig,
    currencies,
    demoAccount,
    setOrderBookSellShowAllSelected,
    markets,
    market,
  } = props

  const [tabIndex, setTabIndex] = useState(0)
  const [showWarningDemoAccount, setShowWarningDemoAccount] = useState(false)

  const selectTabForSelectedOrder = () => {
    if (selectedOrder && selectedOrder.order) {
      const tabIndexToBeSelected = getSelectedTab(selectedOrder)
      setTabIndex(tabIndexToBeSelected)
    }
  }

  useEffect(() => {
    selectTabForSelectedOrder()
  }, [selectedOrder])

  const orderType = getOrderTypeByTabIndex(tabIndex);

  const key = {
    key: selectedOrder && selectedOrder.order
      ? `${selectedOrder.timestamp}__${orderType}`
      : `${orderType}`
  }

  const currency = new Currency(marketConfig)
  const quoteCurrency = getCurrencyBySymbol(currencies, "BRL")
  const marketInfo = markets[market];

  const tokenName = currency.name
  return (
      <AppBar position="static" color="default">
        <Toolbar>
          <div style={{ marginRight: 25 }}>
            <CurrencyIcon currency={marketConfig} />
          </div>
          <Tabs
            value={tabIndex}
            onChange={(event, value) => {
              clearSelectedOrder()
              setTabIndex(value)
            }}
            textColor="primary"
          >
            <Tab
              fullWidth
              classes={{ label: classNames(classes.buyLabel, classes.tabLabel) }}
              label={t('transactions.buy.action', { tokenName })}
            />
            <Tab
              fullWidth
              classes={{ label: classNames(classes.sellLabel, classes.tabLabel) }}
              label={t('transactions.sell.action', { tokenName })}
            />
            <Tab
              fullWidth
              classes={{ label: classNames(classes.stoplossLabel, classes.tabLabel) }}
              label={
                <label className={classes.stoplossLabel}>
                    {t('transactions.stoploss.title')}
                    <InfoTooltip wrapperRootHeader={true} title={t('info.stoploss')}/>
                </label>
              }
            />

          </Tabs>
        </Toolbar>
         <AppCard
            cardClassName={classes.buyLabel}
            noHeader
            cardContentClass={demoAccount ? classes.fade : ''}
            cardHeaderClass={classes.cardHeaderClass}
            cardLabel={t('transactions.buy.action', { tokenName })}
            onMouseEnter ={event => setShowWarningDemoAccount(true)}
            onMouseLeave ={event => setShowWarningDemoAccount(false)}
         >

              {(demoAccount && showWarningDemoAccount) &&
                <UnavailableFeatureDialog featureType={'tradeForm'}/>}
           <TradeOrderForm
              selectedOrder={selectedOrder}
              quoteCurrency={quoteCurrency}
              currency={marketConfig}
              marketInfo={marketInfo}
              markets={markets}
             {...key}
             setOrderBookSellShowAllSelected={setOrderBookSellShowAllSelected}
             orderType={orderType} />
         </AppCard>
        </AppBar>
  )
}

export default compose (
  translate(),
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TradeOrderView)
