import React from "react";
import { InfoOutlined } from "@material-ui/icons";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import TileCard from "../TileCard/TileCard";
import { styles } from "./styles";


const InfoCard = ({ classes, content }) => {
  const infoIcon = <InfoOutlined classes={{
    root: classes.icon
  }} />;

  return <TileCard content={content} leading={infoIcon} />;
};

const enhance = compose(withStyles(styles));

export default enhance(InfoCard);
