import Cookies from 'js-cookie';
import { DEMO_ACCOUNT_EMAIL, DEMO_ACCOUNT_PASS } from '../../../config';
export const redirectToRegister = () => {
    const cookie = Cookies.get('ref');
    const referrer = cookie ? `&r=${Cookies.get('ref')}` : '';
    window.location.href = `https://bity.com.br/register/?product=bitypreco` + referrer;
  }

  export const doLogin = (props) => async (form) => {
    const { onSubmit, updateCaptcha, addMustReloadDialog, googleReCaptchaProps } = props;
    const { executeRecaptcha } = googleReCaptchaProps;
    try {
      const captcha = await executeRecaptcha("login");
      updateCaptcha(captcha);
      onSubmit({ ...form, captcha });
    } catch (e) {
      console.error(e);
      addMustReloadDialog();
    }
  };

  export const loginWithDemoAccount = async (onSubmit) => await onSubmit({
    email: DEMO_ACCOUNT_EMAIL,
    pass: DEMO_ACCOUNT_PASS
  });