import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Grid,
  withStyles,
  Button,
} from "@material-ui/core";
import { translate } from "react-i18next";
import CloseButton from "@material-ui/icons/CloseOutlined";
import classNames from "classnames";
import CountDownSubmitButton from "../../../components/Forms/CountDownSubmitButton";
import { styles } from "./styles";
import InformationRow from "../../../components/Tables/InformationRow/InformationRow";
import CurrencyFormat from "../../../components/Currency/CurrencyFormat";
import { convertToTitleCase } from "../../../utils/strings";
import { formatPercentage } from "../../../utils/NumberFormat/percentage";

const ConfirmConvertionDialog = (props) => {
  const {
    fromCurrency,
    toCurrency,
    brlCurrency,
    handleSubmit,
    quoteValues,
    open,
    handleClose,
    classes,
    t,
    isRequestingQuote,
    getConvertionQuoteWithLastValues,
    canTrade,
  } = props;
  const { fromAmount, toAmount, volumeAmount, expiration, fee } = quoteValues;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const feePercentage = fee && formatPercentage((Number(fee) * 100).toFixed(2));

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const submitRequest = async () => {
    setIsSubmitting(true);
    await handleSubmit();
    if (!isMounted.current) return;
    setIsSubmitting(false);
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      classes={{ paper: classes.paper }}
    >
      <Grid
        align={"center"}
        id="alert-dialog-slide-title"
        className={classes.dialogTitleContainer}
        
      >
        <button className={classes.closeButton}>
          <CloseButton
            className={classes.closeIcon}
            onClick={handleClose}
          ></CloseButton>
        </button>
       <Typography component={"h2"} className={classes.dialogTitle}>{t("screens.cryptoConverter.confirmConvertionModal.title")} </Typography> 

      </Grid>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.informationRowsContainer}>
          <InformationRow
            customClasses={{ boxContainer: classes.informationRow }}
            label={`${convertToTitleCase(t("common.of"))} ${fromCurrency.name}`}
            componentValue={
              <CurrencyFormat
                formatter={fromCurrency.formatter}
                className={"value"}
                value={fromAmount}
              />
            }
          />
          <InformationRow
            customClasses={{ boxContainer: classes.informationRow }}
            label={`${convertToTitleCase(t("common.to"))} ${toCurrency.name}`}
            componentValue={
              <CurrencyFormat
                formatter={toCurrency.formatter}
                className={"value"}
                value={toAmount}
              />
            }
          />
          <InformationRow
            customClasses={{ boxContainer: classes.informationRow }}
            label={t("common.aproximately")}
            isFiat
            componentValue={
              <CurrencyFormat
                formatter={brlCurrency.formatter}
                className={"value"}
                value={volumeAmount}
              />
            }
          />
          <InformationRow
            customClasses={{ boxContainer: classes.informationRow }}
            label={t("common.fee")}
            componentValue={
              <span className={"value"}>{feePercentage}</span>
            }
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          disabled={isSubmitting}
          className={classNames(classes.buttonClose, classes.button)}
          onClick={handleClose}
        >
          {t("common.cancel")}
        </Button>
        <CountDownSubmitButton
          type="button"
          disabled={!canTrade || isSubmitting}
          isSubmitting={isRequestingQuote}
          timestamp={!isRequestingQuote && expiration ? expiration : null}
          onTimeout={getConvertionQuoteWithLastValues}
          className={classNames(classes.buttonConfirm, classes.button)}
          onClick={submitRequest}
          id="confirm_convertion_button"
          submitText={t(
            "screens.cryptoConverter.confirmConvertionModal.confirmButton"
          )}
        />
      </DialogActions>
    </Dialog>
  );
};

export default compose(
  translate(),
  withRouter,
  withStyles(styles)
)(ConfirmConvertionDialog);
