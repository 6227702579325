import { takeLatest, put, select } from 'redux-saga/effects';
import { Currency, getCurrencyBySymbol } from '../../../models/Currency';
import { checkIsRobotAccount } from '../../../utils/accountUtils';
import { updateDataAndCacheRequest } from '../../../utils/sagaUtils';

import moment from 'moment';
import {
  DEPOSIT_INTENTION_REQUESTED,
  DEPOSIT_INTENTION_SUCCEED,
  DEPOSIT_INTENTION_FAILED,
  UPLOAD_DEPOSIT_REQUESTED,
  WITHDRAWAL_INTENTION_REQUESTED,
  CRYPTO_WITHDRAWAL_INTENTION_SUCCEED,
  ADD_WITHDRAWAL_CONFIRMATION_DIALOG,
  WITHDRAWAL_INTENTION_SUCCEED,
  WITHDRAWAL_INTENTION_FAILED,
  CONFIRM_WITHDRAWAL_REQUESTED,
  CONFIRM_WITHDRAWAL_INTENTION_REQUEST,
  CONFIRM_CANCEL_DEPOSIT_INTENTION,
  CANCEL_DEPOSIT_INTENTION_REQUESTED,
  CANCEL_DEPOSIT_INTENTION_FAILED,
  CONFIRM_CANCEL_SEND_COINS,
  CANCEL_SEND_COINS_REQUESTED,
  CANCEL_SEND_COINS_SUCCEED,
  CANCEL_SEND_COINS_FAILED,
} from './actionTypes';

import {
  fetchService,
  fetchPrivateData,
  fetchLimitsRequested,
  addDialog,
  addWithdrawalConfirmation2FADialog,
  closeDialog,
  cancelDepositIntention,
  fetchExtract,
  fetchBalance,
  updateExtract,
  addSnack,
  replaceExtract,
} from '../../actions';

import { getFormat, getImageData } from '../../../utils/imageHelpers';
import { withdrawalIntentionRequested, cancelSendCoins } from './actions';
import { getBankTransferFailedDialogOptions } from './dialogOptions';
import { gtmTransaction } from '../../../utils/dataLayer';

import { addOtpDialog } from '../../credentials/actions';
import { bityPhoenixNotifications, serviceUpdateTypes, typeRequestTimeToLive } from '../../../utils/constants';
import { call } from 'ramda';

export function* depositIntentionSaga({ form }) {
  gtmTransaction('DEPOSIT');
  const isGlobalCardDeposit = form.exch_addr === 'GC|ONLINE';
  const shouldDisplayProcessingDialog = isGlobalCardDeposit;

  if (shouldDisplayProcessingDialog) {
    yield put(
      addDialog({
        title: 'common.processing',
        centerTitle: true,
        disableBackdropClick: true,
        availableChoices: [],
      }),
    );
  }
  yield put(
    fetchService({
      requestType: 'deposit_intention',
      successAction: DEPOSIT_INTENTION_SUCCEED,
      failAction: DEPOSIT_INTENTION_FAILED,
      responseToUser: {
        // a custom message was set in Success Saga
        // onSuccess: 'dialog',
        // onFail: 'dialog'
      },
      paramns: {
        privateService: true,
        form,
        options: {
          cmd: 'deposit_intention',
          method: 'POST',
        },
      },
    }),
  );
}

export function* cancelDepositIntentionSaga({ form }) {
  yield put(
    fetchService({
      requestType: CANCEL_DEPOSIT_INTENTION_REQUESTED,
      failAction: CANCEL_DEPOSIT_INTENTION_FAILED,
      responseToUser: {
        onSuccess: 'dialog',
        onFail: 'dialog',
      },
      paramns: {
        privateService: true,
        form,
        options: {
          // TODO: REVIEW API COMMAND!!!!!!!
          cmd: 'cancel_deposit',
          method: 'POST',
        },
      },
    }),
  );
}

function* confirmCancelDepositIntentionSaga({ form }) {
  try {
    yield put(
      addDialog({
        title: 'transactions.deposit.cancel',
        availableChoices: [
          {
            label: 'common.no',
            actionToTake: '',
          },
          {
            label: 'common.yes',
            actionToTake: 'confirm',
            color: 'secondary',
            variant: 'raised',
          },
        ],
        actionsToTake: {
          confirm: () => cancelDepositIntention(form),
        },
      }),
    );
  } catch (error) {
    console.error('endSessionSaga', error);
  }
}

function* confirmCancelSendCoinsSaga({ form }) {
  try {
    yield put(
      addDialog({
        title: 'transactions.sendCoins.cancel',
        availableChoices: [
          {
            label: 'common.no',
            actionToTake: '',
          },
          {
            label: 'common.yes',
            actionToTake: 'confirm',
            color: 'secondary',
            variant: 'raised',
          },
        ],
        actionsToTake: {
          confirm: () => cancelSendCoins(form),
        },
      },
      ),
    );
  } catch (error) {
    console.error('endSessionSaga', error);
  }
}

export function* cancelSendCoinsSaga({ form }) {
  yield put(
    fetchService({
      requestType: CANCEL_SEND_COINS_REQUESTED,
      successAction: CANCEL_SEND_COINS_SUCCEED,
      failAction: CANCEL_SEND_COINS_FAILED,
      responseToUser: {
        onSuccess: 'dialog',
        onFail: 'dialog',
      },
      paramns: {
        privateService: true,
        form,
        options: {
          cmd: 'cancel_withdrawal',
          method: 'POST',
        },
      },
    }),
  );
}

export function* uploadDepositSaga({ transferDoc, trans_id }) {
  yield put(addDialog({title: 'common.uploading', availableChoices: []}));
  yield put(
    fetchService({
      requestType: 'upload_deposit',
      successAction: DEPOSIT_INTENTION_SUCCEED,
      responseToUser: {
        onFail: 'dialog',
      },
      paramns: {
        privateService: true,
        form: {
          trans_id,
          format: getFormat(transferDoc.mimetype),
          data: getImageData(transferDoc.url),
        },
        options: {
          cmd: 'upload_deposit',
          method: 'POST',
        },
      },
    }),
  );
}

// 3. Displays success message
export function* cryptoTransferSucceedSaga(action) {
  const {
    response: { message_cod: title },
    form,
  } = action;

  const profile = yield select(state => state.user.profile);
  const {has_pin, tax_id} = profile
  const isRobotAccount = checkIsRobotAccount(tax_id);
  const showWarning = !has_pin && !isRobotAccount 

  const successDialogOptions = {
    title: showWarning ? '' : title,
    renderComponent: showWarning ? 'pinIsRequiredToWithdrawalWarning' : '',
    availableChoices: [
      {
        label: 'common.yes', // Validar saque
        actionToTake: showWarning ? '' : 'addOtpDialog',
        color: 'secondary',
        variant: 'raised',
      },
    ],
    actionsToTake:{
      addOtpDialog: () => addWithdrawalConfirmation2FADialog(true, form.currency, form.blockchain),
    },
  };
  yield put(addDialog(successDialogOptions));
  yield put(fetchExtract());
  yield put(fetchBalance());
  yield put(fetchLimitsRequested());
}

// 4. Validates 2FA + PIN sent to email
export function* addWithdrawalConfirmation2FADialogSaga({ isCrypto, currency, blockchain }) {
    yield put(
    addOtpDialog({
      cmd: 'confirm_withdrawal',
      isWithdrawal: true,
      isCryptoWithdrawal: isCrypto,
      currency,
      blockchain,
      onOtpValidatedActionType: 'RESOLVE_OTP_VALIDATE_CONFIRM_WITHDRAWAL',
    }),
  );
}

// 3. Displays success message exclusively when is NOT crypto
export function* bankTransferSucceedSaga(action) {
  const {
    response: { message_cod: title, ...rest },
  } = action;
  let globalcardOptions = {};
  if (rest.data) {
    const { address } = rest.data;
    globalcardOptions = {
      title,
      availableChoices: [
        {
          label: 'common.later',
          actionToTake: '',
          variant: 'raised',
          color: 'link',
        },
        {
          label: 'common.ok',
          actionToTake: 'pay',
          variant: 'raised',
          color: 'secondary',
        },
      ],
      actionsToTake: {
        pay: () => window.open(address, '_blank'),
      },
    };
  }


  const regularTransferOptions = {
    title,
    availableChoices: [
      {
        label: 'common.yes',
        actionToTake: '',
        color: 'secondary',
        variant: 'raised',
      },
    ],
  };

  if (title !== 'FILE_UPLOADED')
    yield put(closeDialog());
  yield put(
    addDialog(title === 'GLOBALCARD_DEPOSIT_CREATED' ? globalcardOptions : regularTransferOptions),
  );
  yield put(fetchLimitsRequested());
  if (['DEPOSIT_INTENTION_SUCCEED'].includes(action.type)) {
    yield put(fetchExtract());
    yield put(fetchBalance());
  }

}

function* getFiatWithdrawalFeeByBankName(bankName) {
  const currencies = yield select(state => state.currencies.currencies);
  const brlCurrency = new Currency(getCurrencyBySymbol(currencies, 'BRL'));
  const withdrawalInfo = bankName.includes('PIX') ? brlCurrency.getWithdrawalInfoByNetwork('PIX') : brlCurrency.getWithdrawalInfoByNetwork('TED');
  return {
    fixedFee: withdrawalInfo.fixedFee,
    percentFee: withdrawalInfo.percentFee,
  }
}

function* fiatUpdateDataAfterTransfer(action) {
  const { message_cod: title, trans_id, address, amount, extraData } = action;

  if (title !== "PENDING_WITHDRAWAL") {
    const formRequest = {
      currency: "BRL,CBRL,BRZ,REALT,RAS",
      type: "WITHDRAWAL,DEPOSIT",
    };
    yield put(fetchExtract(formRequest));
  } else {
    const withdrawalFees = yield call(
      getFiatWithdrawalFeeByBankName,
      extraData.bankName
    );
    const mockedTransaction = {
      id: trans_id,
      created: moment().format("YYYY-MM-DD HH:mm:ss"),
      concluded: null,
      currency: "BRL",
      type: "WITHDRAWAL",
      amount: -Math.abs(amount),
      fee:
        Number(withdrawalFees.fixedFee) +
        (Number(withdrawalFees.percentFee) / 100) * amount,
      balance: null,
      address,
      tx_id: "",
      blockchain: "",
      proof: "",
      confirmations: "0",
      status: "PENDING",
      extra_data: null,
    };
    yield put(updateExtract([mockedTransaction]));
  }
  yield put(fetchLimitsRequested());
  yield put(fetchBalance());
}

export function* bankFiatTransferSucceedSaga(action) {
  const regularTransferOptions = {
    title: action.message_cod,
    availableChoices: [
      {
        label: "common.yes",
        actionToTake: "",
        color: "secondary",
        variant: "raised",
      },
    ],
    renderComponent: ""
  };

  yield put(addDialog(regularTransferOptions));
  yield call(fiatUpdateDataAfterTransfer, action);
}

export function* bankTransferFailedSaga(action) {
  const {
    response: { message_cod: title },
  } = action;

  const dialogOptions = getBankTransferFailedDialogOptions(title);

  yield put(closeDialog())
  yield put(addDialog(dialogOptions));
  yield put(fetchExtract());
  yield put(fetchPrivateData());
  yield put(fetchLimitsRequested());
}

// 2. SEND THE WITHDRAWAL INTENTION REQUEST,
// blocking the UI while request is pending
export function* withdrawalIntentionSaga({ form }) {
  const currencies = yield select(state => state.currencies.currencies);
  const brlCurrencies = ['CBRL', 'RAS'];
  const currencyConfig = getCurrencyBySymbol(currencies, form.currency)
  gtmTransaction('WITHDRAWAL');
  
  const { extraData } = form;
  delete form.extraData;

  const timestamp = moment().format('X');
  const isCrypto = currencyConfig ? !new Currency(currencyConfig).isFiat : !brlCurrencies.includes(form.currency);
  const successAction = isCrypto
    ? CRYPTO_WITHDRAWAL_INTENTION_SUCCEED
    : WITHDRAWAL_INTENTION_SUCCEED;

  if (isCrypto) {
    const title = "common.processing";
    const componentName = "";
    yield put(
      addDialog({
        title: title,
        centerTitle: true,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        renderComponent: componentName,
        availableChoices: [],
      })
    );
    yield put(
      fetchService({
        requestType: "withdrawal",
        successAction,
        failAction: WITHDRAWAL_INTENTION_FAILED,
        paramns: {
          privateService: true,
          form: { ...form, timestamp },
          options: {
            cmd: "withdrawal",
            method: "POST",
          },
        },
      })
    );
  } else {
    const taxId = yield select(state => state.user.profile.tax_id);
    const isRobotAccount = checkIsRobotAccount(taxId);
    if (isRobotAccount) {
      const title = "common.wait";
      const componentName = "withdrawalProgressDialog";
      yield put(
        addDialog({
          title: title,
          centerTitle: true,
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
          renderComponent: componentName,
          availableChoices: [],
        })
      );
      yield put(
        fetchService({
          requestType: "withdrawal",
          successAction,
          failAction: WITHDRAWAL_INTENTION_FAILED,
          paramns: {
            privateService: true,
            form: { ...form, timestamp },
            options: {
              cmd: "withdrawal",
              method: "POST",
            },
          },
        })
      );
    } else {
      yield put(
        addOtpDialog({
          cmd: "withdrawal",
          extraData: extraData,
          customForm: form,
          isWithdrawal: true,
          isCryptoWithdrawal: false,
          currency: form.currency,
          blockchain: form.blockchain,
          onOtpValidatedActionType: "RESOLVE_OTP_VALIDATE_FIAT_WITHDRAWAL",
        })
      );
    }

  }
}

// 1. ASKS THE USER TO CONFIRM WITHDRAWAL INTENTION
function* confirmWithdrawalIntentionSaga({ form }) {
  const componentName = 'confirmAccountWithdrawal';
  const { currency } = form;
  const { address } = form;
  const isBankAccount = address.indexOf("|") !== -1 ? true : false
  const confirmText = currency === 'BTC'
    ? 'confirmBTC'
    : 'confirm';

  try {
    const userName = yield select(state => state.user.profile.name);
    yield put(
      addDialog({
        title: `transactions.withdrawal.${confirmText}`,
        renderComponent: isBankAccount ? componentName : '',
        componentProps: {
          address,
          userName
        },
        availableChoices: [
          {
            label: 'common.no',
            actionToTake: '',
          },
          {
            label: 'common.yes',
            actionToTake: 'confirm',
            color: 'secondary',
            variant: 'raised',
          },
        ],
        actionsToTake: {
          confirm: () => withdrawalIntentionRequested(form),
        },
      }),
    );
  } catch (error) {
    console.error('endSessionSaga', error);
  }
}

// CRIPTO ONLY
// called after click link received by email
function* confirmWithdrawalSaga(form) {
  const {
    token, otp_token, timestamp,
  } = form;
  try {
    yield put(
      fetchService({
        requestType: 'confirmWithdrawal',
        responseToUser: {
          onFail: 'dialog',
        },
        paramns: {
          // privateService: true,
          form: {
            token,
            // currency,
            timestamp,
            otp_token,
          },
          options: {
            cmd: 'confirm_withdrawal',
            method: 'POST',
          },
        },
      }),
    );
  } catch (error) {
    console.error('confirmUpdateProfileSaga', error);
  }
}

function* handleCancelCryptoWithdrawal() {
  yield put(fetchLimitsRequested());
  const typeRequest = bityPhoenixNotifications.COINS_WITHDRAWAL_CANCELED;
  yield updateDataAndCacheRequest({
    updateTypes: [serviceUpdateTypes.TRANSACTIONS],
    typeRequest: typeRequest,
    ttl: typeRequestTimeToLive[typeRequest],
  });
}

export function* concludeTransaction({ trans_id, tx_id, message_cod }) {
  const isWithdrawalConcluded = message_cod === "WITHDRAWAL_CONCLUDED";
  if (isWithdrawalConcluded) {
    const extract = yield select((state) => state.user.extract.extracts);
    const updatedExtract = extract.map((transaction) => {
      if (transaction.id === trans_id) {
        return {
          ...transaction,
          status: "COMPLETE",
          tx_id,
          concluded: moment().format("YYYY-MM-DD HH:mm:ss"),
        };
      }
      return transaction;
    });
    yield put(replaceExtract(updatedExtract));
  }
  const typeRequest = bityPhoenixNotifications.COINS_WITHDRAWAL_COMPLETE;
  const updateType = isWithdrawalConcluded ? serviceUpdateTypes.BALANCE : serviceUpdateTypes.TRANSACTIONS;
  yield updateDataAndCacheRequest({
    updateTypes: [updateType],
    typeRequest: typeRequest,
    ttl: typeRequestTimeToLive[typeRequest],
  });
  yield put(
    addSnack({
      message: typeRequest,
    })
  );
}

export default function* watchBankTransfers() {
  yield takeLatest(UPLOAD_DEPOSIT_REQUESTED, uploadDepositSaga);

  yield takeLatest(DEPOSIT_INTENTION_REQUESTED, depositIntentionSaga);
  yield takeLatest(DEPOSIT_INTENTION_SUCCEED, bankTransferSucceedSaga);
  yield takeLatest(DEPOSIT_INTENTION_FAILED, bankTransferFailedSaga);

  yield takeLatest(WITHDRAWAL_INTENTION_REQUESTED, withdrawalIntentionSaga);
  yield takeLatest(CRYPTO_WITHDRAWAL_INTENTION_SUCCEED, cryptoTransferSucceedSaga);
  yield takeLatest(ADD_WITHDRAWAL_CONFIRMATION_DIALOG, addWithdrawalConfirmation2FADialogSaga);
  yield takeLatest(WITHDRAWAL_INTENTION_SUCCEED, bankTransferSucceedSaga);
  yield takeLatest(WITHDRAWAL_INTENTION_FAILED, bankTransferFailedSaga);

  yield takeLatest(CONFIRM_WITHDRAWAL_INTENTION_REQUEST, confirmWithdrawalIntentionSaga);

  yield takeLatest(CONFIRM_CANCEL_DEPOSIT_INTENTION, confirmCancelDepositIntentionSaga);
  yield takeLatest(CANCEL_DEPOSIT_INTENTION_REQUESTED, cancelDepositIntentionSaga);

  yield takeLatest(CONFIRM_WITHDRAWAL_REQUESTED, confirmWithdrawalSaga);

  yield takeLatest(CONFIRM_CANCEL_SEND_COINS, confirmCancelSendCoinsSaga);
  yield takeLatest(CANCEL_SEND_COINS_REQUESTED, cancelSendCoinsSaga);
  yield takeLatest(CANCEL_SEND_COINS_SUCCEED, handleCancelCryptoWithdrawal);

  yield takeLatest(['RESOLVE_OTP_VALIDATE_CONFIRM_WITHDRAWAL'], concludeTransaction);
  yield takeLatest('RESOLVE_OTP_VALIDATE_FIAT_WITHDRAWAL', bankFiatTransferSucceedSaga);
}
