import { initializeApp } from "firebase/app";
import {
  getRemoteConfig,
  getValue,
  fetchAndActivate,
} from "firebase/remote-config";
import { getAnalytics, logEvent } from 'firebase/analytics';

class FirebaseService {
  constructor() {
    const firebaseConfig = {
      apiKey: "AIzaSyDIhuYCXn9VukHhNXlC3W8b2vXQsC8JrZg",
      authDomain: "stoked-reality-139221.firebaseapp.com",
      projectId: "stoked-reality-139221",
      storageBucket: "stoked-reality-139221.appspot.com",
      messagingSenderId: "530656450156",
      appId: "1:530656450156:web:fc69ce0df560398bbc6a49",
      measurementId: "G-L7TNQM0EWW",
    };
    const admin = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(admin);
    const remoteConfig = getRemoteConfig(admin);
    remoteConfig.defaultConfig = {
      isRFQDisabled: JSON.stringify({
        buy: [""],
      }),
    };
    this.remoteConfig = remoteConfig;
  }
  async fetchAndActiveRemoteConfig() {
    const value = await fetchAndActivate(this.remoteConfig);
    return value;
  }
  isRFQDisabled() {
    const value = JSON.parse(
      getValue(this.remoteConfig, "isRFQDisabled").asString()
    );
    return value;
  }
  depositBankInfo() {
    const value = JSON.parse(
      getValue(this.remoteConfig, "depositBankInfo").asString()
    );
    return value;
  }

  mgmInfo() {
    return JSON.parse(getValue(this.remoteConfig, "mgm").asString());
  }

  tradeAnalyticsEvents(nameEvent, parametersEvent) {
    logEvent(this.analytics, nameEvent, parametersEvent);
  }

  learnMoreMgm() {
    const value = getValue(this.remoteConfig, "learnMoreMgm").asString();
    return value;
  }

  infoDepositBixUrl() {
    return getValue(this.remoteConfig, "infoDepositBixUrl").asString();
  }
  learnMoreBityCardBenefits() {
    const value = getValue(
      this.remoteConfig,
      "learnMoreBityCardBenefits"
    ).asString();
    return value;
  }

  currencyIssues() {
    const value = JSON.parse(
      getValue(this.remoteConfig, "currencyIssues").asString()
    );
    return value;
  }

  cashbackMarketingEvent() {
    const value = JSON.parse(
      getValue(this.remoteConfig, "cashbackMarketingEvent").asString()
    );
    return value;
  }

  disableThrottler() {
    const value = getValue(this.remoteConfig, "disableThrottler").asBoolean();
    return value;
  }

  showCryptoConverter() {
    return getValue(this.remoteConfig, "showCryptoConverter").asBoolean();
  }

  urlBitybankPEPTerms() {
    return getValue(this.remoteConfig, "urlBitybankPEPTerms").asString();
  }

  urlBitybankUseTerms() {
    return getValue(this.remoteConfig, "urlBitybankUseTerms").asString();
  }

  cryptoConverterHowUseArticle() {
    return getValue(this.remoteConfig, "cryptoConverterHowUseArticle").asString();
  }
}
const instance = new FirebaseService();

export { instance as FirebaseService };
