import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import SubmitButton from '../../../components/Forms/SubmitButton';
import Form from '../../../components/Forms/Form';
import FieldRow from '../../../components/Forms/FieldRow';
import PasswordField from '../../../components/Forms/PasswordField';
import EmailField from '../../../components/Forms/EmailField/EmailField';
import { Grid, Typography } from '@material-ui/core';
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles';
import DangerouslySetInnerHTML from '../../../components/DangerouslySetInnerHTML';
import BannerAntiPhishing from '../../../components/Cards/BannerAntiPhishing';
import { redirectToRegister } from './utils';

const styles = theme => ({
  privacy: {
    textAlign: 'center',
    fontWeight: '400',
    color: theme.palette.grey[400],
    '& a:link': {
      color: theme.palette.grey[600],
      textDecoration: 'none'
    },
    '& a:visited': {
      color: theme.palette.grey[600]
    }
  },
  secondaryButtonsAndRecaptchaTextSpacing: {
    padding: '0 1.5rem',
  }
});

const LoginForm = props => {
  const {
    handleSubmit,
    submitting,
    onSubmit,
    invalid,
    t,
    pristine,
    classes,
    logged,
    loginWithDemoAccount,
  } = props;


  return (
    <>
      <BannerAntiPhishing />
      <Form {...{ handleSubmit, onSubmit }}>
        <FieldRow>
          <EmailField
            required
            autoComplete="email"
            name="email"
          />
        </FieldRow>
        <FieldRow>
          <PasswordField
            required
            forbidShowPassword
            autoComplete="current-password"
            name="pass"
          />
        </FieldRow>
        {!logged && <FieldRow>
          <Button
            component={Link}
            to="/resetPassword"
          >
            {t('resetPassword.title')}
          </Button>
        </FieldRow>}
        <FieldRow>
          <SubmitButton
            data-cy={"submit-login"}
            submitText={t(logged ? 'screens.login.submitRenewConnection' : 'screens.login.submit')}
            disabled={(!pristine && (submitting || invalid))}
          />
        </FieldRow>
      </Form>
      <Grid
        container
        direction='column'
        alignItems="center"
        className={classes.secondaryButtonsAndRecaptchaTextSpacing}
      >
        {!logged && <React.Fragment>
          <FieldRow>
            <Button
              data-cy="register-button"
              color="secondary"
              variant="raised"
              // component={Link}
              onClick={redirectToRegister}
              to="/register"
            >
              {t('screens.register.new')}
            </Button>
          </FieldRow>
          <FieldRow>
            <Button
              data-cy="demo-account-button"
              color="secondary"
              variant="raised"
              onClick={e => loginWithDemoAccount()}
            >
              {t('screens.register.demoAccount')}
            </Button>
          </FieldRow>
        </React.Fragment>}
        <FieldRow>
          <Typography className={classes.privacy}>
            <DangerouslySetInnerHTML title={t('forms.privacyRecaptcha')} />
          </Typography>
        </FieldRow>
      </Grid>
    </>
  );
};

LoginForm.defaultProps = {
  submitting: false,
  invalid: false,
  pristine: false,
};

LoginForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
};

export default compose(
  withStyles(styles)
)(LoginForm)
