import {
  put,
  takeLatest,
  call,
  all,
  cancel,
  fork,
  take,
  select,
  takeEvery,
} from "redux-saga/effects";

import { bityPhoenixNotifications, tradeTypes, typeRequestTimeToLive } from "../../utils/constants"

import { delay } from "redux-saga";
import * as R from "ramda";

import {
  updateOrders,
  fetchOrdersRequested,
  fetchExecutedOrdersRequested,
} from "./actions";
import {
  FETCH_ORDERS_REQUESTED,
  DISABLE_SYNC_ORDERS,
  ENABLE_SYNC_ORDERS,
  FETCH_EXECUTED_ORDERS_REQUESTED,
  HANDLE_SUCCESS_EXECUTE_QUOTE,
} from "./actionTypes";
import fetchOpenOrders from "../../services/cbtcService/openOrders";
import fetchExecutedOrders from "../../services/executedOrders";
import fetchOrderBook from "../../services/cbtcService/orderBook";
import { ORDERBOOK_SYNC_INTERVAL, triggerTradeEvent } from "../../config";
import { setEndUpdatingData } from "../market/actions";
import {
  GET_QUOTE,
  UPDATE_QUOTE,
  FAIL_ON_QUOTE,
  EXECUTE_QUOTE,
} from "./actionTypes";
import { fetchService } from "../actions";
import { serviceUpdateTypes } from '../../utils/constants';
import { updateDataAndCacheRequest } from "../../utils/sagaUtils";

const getMyIds = (openOrders) => R.map(R.prop("id"), openOrders);

const getArrays = R.pipe(R.filter(Array.isArray), R.values);

const getMinRowsLength = R.pipe(
  R.chain(getArrays),
  R.map(R.length),
  R.reduce(R.max, 0)
);

const serializeRelation = R.curry((openOrders, myIds) => (order) => {
  const isMine = R.contains(order.id, myIds);
  // eslint-disable-next-line camelcase
  const exec_amount = R.propOr(
    "",
    "exec_amount",
    R.find(R.propEq("id", order.id))(openOrders)
  );

  return { ...order, isMine, exec_amount };
});

const getSortAlgorithm = (orderType) => {
  return orderType === "BUY"
    ? R.descend(R.prop("price"))
    : R.ascend(R.prop("price"));
};

const getMyOptimisticOrders = (myOrders, selectedMarket) => {
  return myOrders.filter(
    (order) => order.saving && order.market === selectedMarket
  );
};

const ordersWithOptimistic = (
  ordersSerialized,
  myLocalOpenOrders,
  orderBookTimestamp,
  selectedMarket,
  orderType
) => {
  const optimisticOrders = myLocalOpenOrders.filter(
    (order) => order.saving && order.market === selectedMarket
  );

  if (optimisticOrders.length > 0) {
    const orderbookTimestampTime = new Date(orderBookTimestamp).getTime();
    const optimisticOrdersToKeep = optimisticOrders.filter(
      (order) => new Date(order.time_stamp).getTime() > orderbookTimestampTime
    );

    if (optimisticOrdersToKeep.length > 0) {
      const ordersMarkedWithIsMine = optimisticOrdersToKeep.map((order) => {
        return { ...order, isMine: true };
      });
      return R.sort(
        getSortAlgorithm(orderType),
        R.concat(ordersMarkedWithIsMine, ordersSerialized)
      );
    } else return ordersSerialized;
  } else return ordersSerialized;
};

function* fetchExecutedOrdersSaga() {
  try {
    const market = yield select((state) => state.market.selectedMarket);
    const demoAccount = yield select((state) => state.credentials.demoAccount);
    if (!demoAccount) {
      const executedOrders = yield call(() => fetchExecutedOrders(market));
      if (executedOrders) {
        yield put(
          updateOrders({
            executedOrders,
          })
        );
      }
    }
  } catch (e) {
    console.log("err 🛑 FETCH_EXECUTED_ORDERS", e);
  }
}

function* handleOrderBookFromSockets({ orderBook }) {
  try {
    const sellOrders = orderBook.asks;
    const buyOrders = orderBook.bids;
    const timestamp = orderBook.timestamp;
    const market = yield select((state) => state.market.selectedMarket);

    const mySellOpenOrders = yield select((s) => s.orders.sellOpenOrders);
    const myBuyOpenOrders = yield select((s) => s.orders.buyOpenOrders);
    const openOrderBooks = {
      SELL: mySellOpenOrders,
      BUY: myBuyOpenOrders,
    };
    const sellOpenOrders = openOrderBooks.SELL;
    const buyOpenOrders = openOrderBooks.BUY;

    const mySellIds = getMyIds(sellOpenOrders);
    const myBuyIds = getMyIds(buyOpenOrders);

    const minRowsLength = getMinRowsLength([orderBook, openOrderBooks]);

    const sellOrdersSerialized = sellOrders.map(
      serializeRelation(sellOpenOrders, mySellIds)
    );
    const buyOrdersSerialized = buyOrders.map(
      serializeRelation(buyOpenOrders, myBuyIds)
    );

    const sellOrdersWithOptimistic = ordersWithOptimistic(
      sellOrdersSerialized,
      sellOpenOrders,
      timestamp,
      market,
      "SELL"
    );
    const buyOrdersWithOptimistic = ordersWithOptimistic(
      buyOrdersSerialized,
      buyOpenOrders,
      timestamp,
      market,
      "BUY"
    );

    yield put(
      updateOrders({
        sellOpenOrders,
        buyOpenOrders,
        minRowsLength,
        sellOrdersSerialized: sellOrdersWithOptimistic,
        buyOrdersSerialized: buyOrdersWithOptimistic,
      })
    );
    yield put(setEndUpdatingData());
  } catch (err) {
    console.error("fetchOrdersSaga", err);
  }
}

function* fetchOrdersSaga() {
  try {
    const market = yield select((state) => state.market.selectedMarket);
    const [orderBook, openOrders] = yield all([
      call(() => fetchOrderBook(market)),
      call(() => fetchOpenOrders(market)),
    ]);

    if (orderBook && openOrders) {
      const sellOrders = orderBook.asks;
      const buyOrders = orderBook.bids;
      const timestamp = orderBook.timestamp;

      const myStateSellOpenOrders = yield select(
        (s) => s.orders.sellOpenOrders
      );
      const myStateBuyOpenOrders = yield select((s) => s.orders.buyOpenOrders);

      const mySellOptimisticOpenOrders = getMyOptimisticOrders(
        myStateSellOpenOrders,
        market
      );
      const myBuyOptimisticOpenOrders = getMyOptimisticOrders(
        myStateBuyOpenOrders,
        market
      );

      const myOpenOrders = openOrders
        ? R.groupBy((order) => {
            return order.type;
          }, openOrders)
        : [];

      const openOrderBooks = {
        SELL: myOpenOrders.SELL ? myOpenOrders.SELL : [],
        BUY: myOpenOrders.BUY ? myOpenOrders.BUY : [],
      };

      const sellOpenOrders = openOrderBooks.SELL.concat(
        mySellOptimisticOpenOrders
      );
      const buyOpenOrders = openOrderBooks.BUY.concat(
        myBuyOptimisticOpenOrders
      );

      const sellOpenOrdersFromApiFetch = openOrderBooks.SELL;
      const buyOpenOrdersFromApiFetch = openOrderBooks.BUY;

      const mySellIds = getMyIds(sellOpenOrders);
      const myBuyIds = getMyIds(buyOpenOrders);

      const minRowsLength = getMinRowsLength([orderBook, openOrderBooks]);

      const sellOrdersSerialized = sellOrders.map(
        serializeRelation(sellOpenOrders, mySellIds)
      );
      const buyOrdersSerialized = buyOrders.map(
        serializeRelation(buyOpenOrders, myBuyIds)
      );

      const sellOrdersWithOptimistic = ordersWithOptimistic(
        sellOrdersSerialized,
        sellOpenOrders,
        timestamp,
        market,
        "SELL"
      );
      const buyOrdersWithOptimistic = ordersWithOptimistic(
        buyOrdersSerialized,
        buyOpenOrders,
        timestamp,
        market,
        "BUY"
      );

      yield put(
        updateOrders({
          minRowsLength,
          sellOpenOrders: sellOpenOrdersFromApiFetch,
          buyOpenOrders: buyOpenOrdersFromApiFetch,
          sellOrdersSerialized: sellOrdersWithOptimistic,
          buyOrdersSerialized: buyOrdersWithOptimistic,
        })
      );
    }
  } catch (err) {
    console.error("fetchOrdersSaga", err);
  } finally {
    yield put(setEndUpdatingData());
  }
}

function* removeOrdersSaga() {
  yield put(
    updateOrders({
      sellOrdersSerialized: [],
      buyOrdersSerialized: [],
      sellOpenOrders: [],
      buyOpenOrders: [],
      executedOrders: [],
    })
  );
}

function* handleSuccessExecuteQuote() {
  const rfqData = yield select((state) => state.orders.RFQ);
  const parametersEvent = {
    isBuy: rfqData?.customer_side == "BUY" ? 1 : 0,
    price: rfqData?.price,
    amount: rfqData?.quote_amount,
    market: rfqData?.market,
    limited: 0,
  };
  triggerTradeEvent("trade", parametersEvent);
  const typeRequest = bityPhoenixNotifications.ORDER_FULLY_EXECUTED;
  yield updateDataAndCacheRequest({
    updateTypes: [
      serviceUpdateTypes.TRANSACTIONS,
      serviceUpdateTypes.ORDER,
      serviceUpdateTypes.EXECUTED_ORDERS,
    ],
    typeRequest,
    ttl: typeRequestTimeToLive[typeRequest],
  });
}

function* executeQuoteSaga({ quoteId }) {
  const payload = {
    quote_id: quoteId,
  };
  yield put(
    fetchService({
      requestType: EXECUTE_QUOTE,
      successAction: HANDLE_SUCCESS_EXECUTE_QUOTE,
      successActionHasBeep: true,
      responseToUser: {
        onSuccess: "snack",
        onFail: "snack",
      },
      paramns: {
        privateService: true,
        form: payload,
        options: {
          cmd: "execute_quote",
          method: "POST",
        },
      },
    })
  );
}

function* getQuoteSaga({ param, inputValue, orderType, market }) {
  const payload = {
    [param]: inputValue,
    type: orderType,
    market: market,
    source: tradeTypes.express,
  };

  yield put(
    fetchService({
      requestType: "get_quote",
      successAction: UPDATE_QUOTE,
      failAction: FAIL_ON_QUOTE,
      paramns: {
        privateService: true,
        form: payload,
        options: {
          cmd: "get_quote",
          method: "POST",
        },
      },
    })
  );
}

function* enableSyncOrdersSaga() {
  while (true) {
    try {
      if (!document.hidden) {
        // will fetch orderbook & open orders
        yield put(fetchOrdersRequested());
        yield call(delay, ORDERBOOK_SYNC_INTERVAL);
      } else {
        yield call(delay, ORDERBOOK_SYNC_INTERVAL);
      }
    } catch (error) {
      console.error("enableSyncOrdersSaga", error);
      yield call(delay, ORDERBOOK_SYNC_INTERVAL);
    }
  }
}

function* toggleSyncOrdersSaga() {
  while (yield take(ENABLE_SYNC_ORDERS)) {
    yield put(fetchExecutedOrdersRequested());
    const bgSyncTask = yield fork(enableSyncOrdersSaga);

    yield take(DISABLE_SYNC_ORDERS);
    yield cancel(bgSyncTask);
  }
}

function* watchOrders() {
  yield takeLatest("HANDLE_ORDERBOOK_FROM_SOCKETS", handleOrderBookFromSockets);
  yield takeLatest(GET_QUOTE, getQuoteSaga);
  yield takeLatest(EXECUTE_QUOTE, executeQuoteSaga);
  yield takeEvery(FETCH_ORDERS_REQUESTED, fetchOrdersSaga);
  yield takeLatest(FETCH_EXECUTED_ORDERS_REQUESTED, fetchExecutedOrdersSaga);
  yield takeLatest("REMOVE_CREDENTIALS", removeOrdersSaga);
  yield takeLatest(HANDLE_SUCCESS_EXECUTE_QUOTE, handleSuccessExecuteQuote);
  yield toggleSyncOrdersSaga();
}

export default watchOrders;
