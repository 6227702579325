import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { translate } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import TransactionButton from './TransactionButton';

const styles = theme => ({
  stoplossButton: theme.stoplossButton,
  stoplossTextPrimary: theme.stoplossButtonTextPrimary,
});

const enhance = compose(
  translate(),
  withStyles(styles),
);

const StoplossButton = props => {
  const { t, classes, symbol = "" } = props;

  return (
    <TransactionButton
      className={classes.stoplossButton}
      textPrimary={classes.stoplossTextPrimary}
      to="/stoploss"
    >
      {t('transactions.stoploss.action', { tokenName: symbol })}
    </TransactionButton>
  );
};

StoplossButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default enhance(StoplossButton);
