import React from "react";
import KnowMoreBase from "../KnowMoreBase/KnowMoreBase";
import { withStyles } from "@material-ui/core";
import { styles } from "./styles";
import compose from "recompose/compose";

export const KnowMoreLink = ({ text, urlLink, classes }) => {
  return (
    <a href={urlLink} target="_blank" rel="noreferrer" className={classes.linkContainer}>
      <KnowMoreBase text={text} />
    </a>
  );
};

const enhance = compose(withStyles(styles));

export default enhance(KnowMoreLink);
