import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { translate } from "react-i18next";
import CashbackIndicator from "./components/CashbackIndicator";
import ProgressCard from "./components/ProgressCard";
import MissionCard from "./components/MissionCard";
import SectionCard from "../SectionCard";
import ValueBox from "../ValueBox";
import DonutChart from "../../../components/Charts/DonutChart";
import classNames from "classnames";
import { formatPercentage } from "../../../utils/NumberFormat/percentage";
import {
  FIREBASE_LINKS,
  FIREBASE_TRANSLATIONS,
  FIREBASE_MGM_INFO,
} from "../../../config";
import { formatToBRL } from "../../../utils/NumberFormat/moneyFormats";

const styles = (theme) => ({
  mainContainer: {
    maxWidth: "52.899022801303%",
    margin: "50px auto 0",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "87%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "90%",
    },
    position: "relative",

  },
  cashbackCompositionSection: {
    position: "relative",
  },
  dailyCashbackSection: {
    width: "100%",
    minHeight: "320px",
    padding: "50px 12.315270935961%",
    [theme.breakpoints.down("md")]: {
      padding: "50px 10%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px 20px",
    },
  },
  title: {
    color: "#101010",
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    margin: "0 0 10px",
  },
  defaultGap: {
    gap: "10px",
  },
  progressBarsContainer: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  cashbackIndicatorWrapper: {
    position: "absolute",
    top: -28,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  cashbackSplitTotalRow: {
    marginTop: "20px",
    gap: "20px",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  valueBoxRow: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },

    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },

    [theme.breakpoints.up(1205)]: {
      flexDirection: "row",
    },
  },
  totalCashbackSubtitle: {
    color: "#696F74",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    marginTop: "20px",
  },
  flexColumn: {
    flexDirection: "column !important",
  },
  emptyChartCustomClass: {
    "@media screen and (max-width: 959px)": {
      height: "200px",
    },
  },
});

const Cashback = (props) => {
  const { classes, t, userData } = props;
  const { cashbackInfo, cashbackTotal, cashbackChartItems, referrerConf } = userData;
  const bonusCardReferral = referrerConf?.bonus_card_referral || {};

  const cashbackComposition = cashbackInfo.cashback_composition;
  const cardVolumeProgress = cashbackInfo.isLoading
    ? null
    : cashbackComposition.first_month !== 0
      ? 1
      : cashbackComposition.card_volume;
  const hasFirstMonthBonus =
    cashbackComposition.first_month !== 0 &&
    cashbackComposition.first_month !== null;
  const hasMarketingEventBonus =
    cashbackComposition.marketing_event !== 0 &&
    cashbackComposition.marketing_event !== null;
  const hasCashbackBonusMarketingEvent =
    cashbackComposition.cashback_bonus_marketing_event !== 0 &&
    cashbackComposition.cashback_bonus_marketing_event !== null;
  const mainBonusesClass =
    hasMarketingEventBonus || hasFirstMonthBonus
      ? classNames(classes.defaultGap, classes.valueBoxRow)
      : classNames(classes.defaultGap, classes.valueBoxRow, classes.flexColumn);
  const toolTipChartFormatter = (value) => {
    return `R$ ${Number(value).toLocaleString("pt-BR", {
      maximumFractionDigits: 2,
    })}`;
  };
  const cashbackListItems = Object.entries(cashbackChartItems).map(
    ([_, item]) => {
      return {
        title: item.title,
        value: item.value,
        color: item.color,
      };
    }
  );

  const totalCashbackPercentage = formatPercentage(cashbackComposition.total);
  const cashbackMarketingEventTexts =
    FIREBASE_TRANSLATIONS.cashbackMarketingEvent;
  let cashbackBonusMarketingEventDescriptions =
    cashbackMarketingEventTexts.descriptions
      ? cashbackMarketingEventTexts.descriptions.map((string, _) => {
        return `${string}\n`;
      })
      : [""];

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.mainContainer}
    >
      <SectionCard
        className={classes.dailyCashbackSection}
        title={`${t(
          "screens.mgm.cashback.dailyCashback.title"
        )} ${totalCashbackPercentage}`}
      >
        <div className={classes.cashbackIndicatorWrapper}>
          <CashbackIndicator content={totalCashbackPercentage} size={1.3} />
        </div>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            className={classNames(
              classes.defaultGap,
              classes.progressBarsContainer
            )}
          >
            {!hasCashbackBonusMarketingEvent && (
              <ProgressCard
                progress={cardVolumeProgress}
                levelsAmount={FIREBASE_MGM_INFO.totalStepsCardProgress}
                max={FIREBASE_MGM_INFO.maxCardCashback}
                steps={["0%", "0.5%", "1%"]}
                label={t("screens.mgm.cashback.dailyCashback.cardPurchases")}
              />
            )}
            <ProgressCard
              progress={cashbackComposition.mgm}
              levelsAmount={FIREBASE_MGM_INFO.totalStepsMgmProgress}
              max={FIREBASE_MGM_INFO.maxMgmCashback}
              steps={["0%", "5%", "10%"]}
              label={t("screens.mgm.indications.title")}
            />
          </Grid>
          <MissionCard userData={userData} />
        </Grid>
      </SectionCard>

      <Grid container direction="row" className={classes.cashbackSplitTotalRow}>
        <SectionCard
          classNames={"cashbackCompositionSection"}
          title={t("screens.mgm.cashback.cashbackSplit.title")}
          titleChildrenGap={"20px"}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            className={classes.defaultGap}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              className={mainBonusesClass}
            >
              <ValueBox
                hasToolTip
                knowMoreLink={FIREBASE_LINKS.learnMoreBityCardBenefits}
                value={formatPercentage(cashbackComposition.card_volume)}
                toolTipLabel={t(
                  "screens.mgm.cashback.cashbackSplit.transaction"
                )}
                toolTipText={t(
                  "screens.mgm.cashback.cashbackSplit.transactionDescription"
                )}
              />
              <ValueBox
                hasToolTip
                knowMoreLink={FIREBASE_LINKS.learnMoreMgm}
                value={formatPercentage(cashbackComposition.mgm)}
                toolTipLabel={t(
                  "screens.mgm.cashback.cashbackSplit.referAndWin"
                )}
                toolTipText={t(
                  "screens.mgm.cashback.cashbackSplit.mgmDescription",
                  { referrerBonus: formatToBRL(bonusCardReferral.referrer_bonus || '20'), userBonus: formatToBRL(bonusCardReferral.user_bonus || '20') }
                )}
              />
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              className={classNames(classes.defaultGap, classes.valueBoxRow)}
            >
              {hasMarketingEventBonus && (
                <ValueBox
                  hasToolTip
                  value={formatPercentage(cashbackComposition.marketing_event)}
                  toolTipLabel={
                    cashbackMarketingEventTexts.title
                      ? cashbackMarketingEventTexts.title
                      : ""
                  }
                  toolTipText={cashbackBonusMarketingEventDescriptions.join("")}
                />
              )}
              {hasFirstMonthBonus && (
                <ValueBox
                  hasToolTip
                  value={formatPercentage(cashbackComposition.first_month)}
                  toolTipLabel={t(
                    "screens.mgm.cashback.cashbackSplit.firstMonthBonus"
                  )}
                  toolTipText={t(
                    "screens.mgm.cashback.cashbackSplit.firstMonthBonusDescription"
                  )}
                />
              )}
            </Grid>
          </Grid>
        </SectionCard>
        <SectionCard
          title={t("screens.mgm.cashback.totalCashback.title")}
          titleChildrenGap={"20px"}
        >
          <ValueBox
            value={`≈ R$ ${cashbackTotal.toLocaleString("pt-BR", {
              maximumFractionDigits: 2,
            })}`}
          />
          <Typography align="center" className={classes.totalCashbackSubtitle}>
            {t(
              "screens.mgm.cashback.totalCashback.checkYourCashbackComposition"
            )}
          </Typography>
          <DonutChart
            chartItems={cashbackListItems}
            emptyChartCustomClass={classes.emptyChartCustomClass}
            toolTipFormatter={toolTipChartFormatter}
          />
        </SectionCard>
      </Grid>
    </Grid>
  );
};

const enhance = compose(withStyles(styles), translate());
export default enhance(Cashback);
