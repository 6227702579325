export const styles = (_theme) => ({
  tileCardContentContainer: {
    gap: "10px",
  },
  iconContainer: {
    maxWidth: "fit-content",
  },
  content: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    fontWeight: "normal",
    color: _theme.bitybankColors.tertiary,
    margin: "0",

    "& a": {
      fontWeight: "600",
      textDecoration: "none",
      color: _theme.bitybankColors.tertiary,
    },
  },
  card: {
    boxShadow: "none",
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    "& :last-child": {
      paddingBottom: "0",
    },
  },
  cardContent: {
    padding: "0",
  },
});
