import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { translate } from "react-i18next";
import SectionCard from "../SectionCard";
import ValueBox from "../ValueBox";
import classNames from "classnames";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import {
  ChevronLeft,
  ChevronRight,
  CheckCircleOutline,
} from "@material-ui/icons";
import CurrencyFormat from "../../../components/Currency/CurrencyFormat";
import { Currency } from '../../../models/Currency';

function createData({
  indicationId,
  validatedRegister,
  totalNegotiated,
  tradingVolume,
  oneThousand,
  tenThousand,
  thirtyThousand,
  sixtyThousand,
  oneHundredThousand
}) {
  var validatedRegister = validatedRegister === "1" ? "Sim" : "Não";
  return {
    indicationId,
    validatedRegister,
    totalNegotiated,
    tradingVolume,
    oneThousand,
    tenThousand,
    thirtyThousand,
    sixtyThousand,
    oneHundredThousand,
  };
}

const styles = (theme) => ({
  mainContainer: {
    maxWidth: "80%",
    margin: "50px auto 0",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "90%",
    },
    position: "relative",
  },
  totalRewardsSection: {
    width: "100%",
    padding: "50px 12.315270935961%",
    [theme.breakpoints.down("md")]: {
      padding: "50px 10%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px 20px",
    },
  },
  totalRewardsContainer: {
    margin: "0 auto",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  totalRewardsBox: {
    width: "70%",
  },
  defaultGap: {
    gap: "10px",
  },
  negotiationData: {
    overflow: "hidden",
    maxWidth: "100%",
    marginTop: "20px",
    gap: "20px",

    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
  },
  subtitle: {
    maxWidth: "100%",
    margin: 0,
    color: "#696F74",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "center",
  },
  tableResponsiveContainer: {
    overflowX: "auto",
    margin: "0 auto",
    minHeight: "525px",

    [theme.breakpoints.down("md")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  tableHead: {
    background: "#F7B733",
    height: "44px",

    "& th": {
      color: "#101010",
      fontFamily: "Roboto",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
      border: "none",
    },
  },
  tableBodyCell: {
    color: "#696F74",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    borderColor: "#EAEAEA;",
  },
  pagination: {
    padding: "0",
    marginTop: "20px",
    listStyle: "none",
    gap: "10px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",

    "& li": {
      width: "40px",
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      borderRadius: "5px",
    },

    "& .next-and-previous": {
      background: "#F7B733",

      "& svg": {
        width: "40px",
      },
    },

    "& .selected": {
      border: "1px solid #D6D1B4",
      background: "#FEF4D2",
      color: "#101010",
      fontFamily: "Roboto",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px" /* 200% */,
    },

    "& li:not(.selected):not(.next-and-previous)": {
      color: "#696F74",
      fontFamily: "Roboto",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      background: "#EAEAEA",

      "&:hover": {
        background: "#F7B733",
        color: "#101010",
      },

      "& a": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      },
    },
  },
  checkMarkIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  checkMarkIcon: {
    color: "#1BB245 !important",
  },
  emptyTableMessageWrapper: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minHeight: "525px",
    "& p": {
      width: "100%",
      margin: 0,
      color: "#696F74",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "24px",
      textAlign: "center",
    },
  },
});

const Reward = (props) => {
  const { classes, t, userData, brlCurrency } = props;
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  const { referralsData, referrerConf, rewards } = userData;
  const currencyConfig = new Currency(brlCurrency)

  const bonusPremiumData = referrerConf?.bonus_premium_volume || {};
  const tradingVolume =  bonusPremiumData?.trading_volume || '';

  const { tier1 = 1, tier2 = 10, tier3 = 30, tier4 = 60, tier5 = 100 } = rewards;

  const rows = referralsData.referrals.map((referral) => {
    const { user_id, kyc_done, volume, prize1, prize2, prize3, prize4, prize5 } = referral
    return createData({
      indicationId: user_id,
      validatedRegister: kyc_done,
      totalNegotiated: volume,
      tradingVolume: !prize1 ? '' : tradingVolume ,
      oneThousand: prize1,
      tenThousand: prize2,
      thirtyThousand: prize3,
      sixtyThousand: prize4,
      oneHundredThousand: prize5
    })
  });

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(rows.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(rows.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % rows.length;
    setItemOffset(newOffset);
  };


  const toThousandUnit = (value) => {
    return Number(value / 1000);
  }

  const columns = [
    {
      id: "indicationId",
      label: t("screens.mgm.rewards.tableColumns.indicationId"),
      borderRadius: "5px 0 0 5px",
      align: "left",
    },
    {
      id: "validatedRegister",
      label: t("screens.mgm.rewards.tableColumns.validatedRegister"),
    },
    {
      id: "totalNegotiated",
      label: t("screens.mgm.rewards.tableColumns.totalNegotiated"),

      align: "right",
    },
    tradingVolume ? 
    {
      id: "tradingVolume",
      label: t("screens.mgm.rewards.tableColumns.defaultTradingVolume", { tradingVolume: bonusPremiumData.trading_volume || '' }),
      align: "center",
      textAlign: "center",
    } : {},
    {
      id: "oneThousand",
      label: t("screens.mgm.rewards.tableColumns.thousandValue", { value: toThousandUnit(tier1) }),
      align: "center",
      textAlign: "center",
    },
    {
      id: "tenThousand",
      label: t("screens.mgm.rewards.tableColumns.thousandValue", { value: toThousandUnit(tier2) }),
      align: "center",
      textAlign: "center",
    },
    {
      id: "thirtyThousand",
      label: t("screens.mgm.rewards.tableColumns.thousandValue", { value: toThousandUnit(tier3) }),
      align: "center",
      textAlign: "center",
    },
    {
      id: "sixtyThousand",
      label: t("screens.mgm.rewards.tableColumns.thousandValue", { value: toThousandUnit(tier4) }),
      align: "center",
      textAlign: "center",
    },
    {
      id: "oneHundredThousand",
      label: t("screens.mgm.rewards.tableColumns.thousandValue", { value: toThousandUnit(tier5) }),
      align: "center",
      textAlign: "center",
      borderRadius: "0 5px 5px 0",
    },
  ];

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.mainContainer}
    >
      <SectionCard
        className={classes.totalRewardsSection}
        title={t("screens.mgm.rewards.totalRewards")}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classNames(
            classes.defaultGap,
            classes.totalRewardsContainer
          )}
          dire
        >
          <Typography className={classes.subtitle}>
            {t("screens.mgm.rewards.totalRewardsDescription")}
          </Typography>
          <div className={classes.totalRewardsBox}>
            <ValueBox value={`R$ ${referralsData.totalEarned}`} />
          </div>
        </Grid>
      </SectionCard>
      <Grid container direction="row" className={classes.negotiationData}>
        <SectionCard
          title={t("screens.mgm.rewards.infoAboutUserNegotiations")}
          titleChildrenGap={"20px"}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            className={classes.defaultGap}
          >
            <Typography className={classes.subtitle}>
              {t("screens.mgm.rewards.detailedInfoUserNegotiatonsMobileCta")}
            </Typography>
            <div className={classes.tableResponsiveContainer}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={classes.tableHead}>
                    {columns?.map((column) => {
                      if(!Object.values(column).length) return;
                      return (
                      <TableCell
                        key={column?.id}
                        align={column?.align}
                        style={{
                          minWidth: column?.minWidth,
                          borderRadius: column?.borderRadius,
                        }}
                      >
                        {column?.label}
                      </TableCell>
                    )})}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentItems && currentItems.length ? (
                    currentItems.map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns?.map((column) => {
                            if(!Object.values(column).length) return;
                            let value;
                            const checkMarkColumns = [
                              "tradingVolume",
                              "oneThousand",
                              "tenThousand",
                              "thirtyThousand",
                              "sixtyThousand",
                              "oneHundredThousand",
                            ];
                            if (checkMarkColumns.includes(column?.id)) {
                              value = row[column?.id] ? (
                                <div className={classes.checkMarkIconWrapper}>
                                  <CheckCircleOutline
                                    classes={{ root: classes.checkMarkIcon }}
                                  />
                                </div>
                              ) : (
                                ""
                              );
                            } else if (column?.id === "totalNegotiated") {
                              value = (
                                <CurrencyFormat
                                  isFiat={currencyConfig.isFiat}
                                  formatter={currencyConfig._defaultFormatter()}
                                  value={row[column?.id]}
                                />
                              );
                            } else {
                              value = row[column?.id];
                            }

                            return (
                              <TableCell
                                key={column?.id}
                                align={column?.align}
                                className={classes.tableBodyCell}
                              >
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  ) : (
                    <td colSpan={8}>
                      <div className={classes.emptyTableMessageWrapper}>
                        <Typography
                          className={classes.emptyTableMessageWrapper}
                        >
                          {t("screens.mgm.rewards.notReferralYet")}
                        </Typography>
                      </div>
                    </td>
                  )}
                </TableBody>
              </Table>
            </div>

            {referralsData.referrals.length > 0 && (
              <ReactPaginate
                onPageChange={handlePageClick}
                nextLabel={<ChevronRight />}
                previousLabel={<ChevronLeft />}
                className={classes.pagination}
                pageCount={pageCount}
                previousClassName={"next-and-previous"}
                nextClassName={"next-and-previous"}
                brea
              />
            )}
          </Grid>
        </SectionCard>
      </Grid>
    </Grid>
  );
};

const enhance = compose(withStyles(styles), translate());

Reward.propTypes = {
  userData: PropTypes.object.isRequired,
};

Reward.defaultProps = {
  userData: {},
};

export default enhance(Reward);
