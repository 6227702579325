import { merge } from 'ramda';
import * as actions from './actionTypes';

const initialState = {
  open: false,
};


const addDialog = (state, action) => {
  return merge(state, {
    open: true,
    ...action,
  });
};


const closeDialog = (state, action) => {
  return merge(state, {
    open: false,
    renderComponent: null,
    content: null,
    ...action,
  });
};

const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ADD_DIALOG:
      return addDialog(state, action);

    case actions.CLOSE_DIALOG:
      return closeDialog(state, action);

    default:
      return state;
  }
};

export default dialogReducer;
