import React from "react";
import { withStyles } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import compose from "recompose/compose";
import { styles } from "./styles";

export const KnowMoreBase = ({ text, classes }) => {
  return (
    <div className={classes.mainContainer}>
      <HelpOutline
        classes={{
          root: classes.icon,
        }}
      />
      {text}
    </div>
  );
};

const enhance = compose(withStyles(styles));

export default enhance(KnowMoreBase);
