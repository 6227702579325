import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import DangerouslySetInnerHTML from "../../DangerouslySetInnerHTML";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";

const TileCard = ({ leading, trailing, content, classes, customClasses }) => {
  return (
    <Card
      classes={{
        root: customClasses?.card || classes.card,
      }}
    >
      <CardContent
        classes={{
          root: classes.cardContent,
        }}
      >
        <Grid
          container
          className={classes.tileCardContentContainer}
          wrap="nowrap"
          justify="space-between"
          alignItems="center"
        >
          {leading && (
            <Grid className={classes.iconContainer} item xs={1}>
              {leading}
            </Grid>
          )}
          <Grid item xs={12}>
            <DangerouslySetInnerHTML
              className={classes.content}
              title={content}
              Component="p"
            />
          </Grid>

          {trailing && (
            <Grid item className={classes.iconContainer} md={1}>
              {trailing}
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

const enhance = compose(withStyles(styles));

export default enhance(TileCard);
