export const styles = (theme) => ({
  mainContainer: {
    display: "flex",
    alignItems: "center",
    gap: "3px",
    textDecoration: "none",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    color: theme.bitybankColors.tertiary,
    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
  },

  icon: {
    color: theme.bitybankColors.tertiary,
    fontSize: "17px",
  },
});
