import React, { useEffect } from "react";
import { compose, } from "recompose";
import { Route } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PageTabs from "../../components/PageLayouts/PageTabs";
import BannerBitybank from "../../components/Cards/BannerBitybank";
import { Typography } from "@material-ui/core";
import StoreLinks from "../../components/Buttons/StoreLinks";
import Cashback from "./Cashback/Cashback";
import Indication from "./Indication/Indication";
import Reward from "./Reward/Reward";
import { toDecimalPrecision } from "../../utils/NumberFormat/roundValues";
import { getCurrencyBySymbol, Currency } from '../../models/Currency'
import { isValidNumber } from "../../utils/numbers";
import { formatToBRL } from "../../utils/NumberFormat/moneyFormats";
import { FIREBASE_MGM_INFO } from "../../config";

const styles = (theme) => ({
  header: {
    marginTop: "70px",
    "& p.content": {
      fontSize: "17px",
    },
    "& p.subtitle": {
      fontSize: "13px",
    },
    "& img.app-image": {
      "@media screen and (max-width: 1420px)": {
        display: "none",
      },
    },
    "@media screen and (max-width: 576px)": {
      marginTop: "0px",
    },
  },
  footer: {
    marginTop: "50px",
    boxSizing: "border-box",
    margin: "0 -15px 64px -15px",

    "& img.app-image": {
      "@media screen and (max-width: 1100px)": {
        display: "none",
      },
    },
    "@media screen and (max-width: 959px)": {
      margin: "0 0 -8px",
    },
  },
  tabs: {
    width: "80%",
  },
  tabStyle: {
    transition:
      "filter 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  tabNotSelected: {
    borderRadius: "5px",
    border: `2px solid ${theme.palette.secondary.main}`,

    "&:hover": {
      backgroundColor: "#e6e6e7",
      boxShadow:
        "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    },
  },
  tabSelected: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "5px",

    "&:hover": {
      filter: "brightness(90%)",
      boxShadow:
        "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    },
  },
  pageTabs: {
    maxWidth: "52.899022801303%",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "87%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "90%",
    },
    position: "relative",

    margin: "auto",
    "& div": {
      gap: "10px",
    },
  },
  tabNotSelectedText: {
    color: "#696F74",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  colorWhite: {
    color: "#FFFFFF",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
});

const getTabs = (t) => [
  {
    label: t("screens.mgm.indications.title"),
    value: "indications",
  },
  {
    label: t("screens.mgm.cashback.title"),
    value: "cashback",
  },
  {
    label: t("screens.mgm.rewards.title"),
    value: "rewards",
  },
];

const MGM = (props) => {
  const {
    handleChange,
    t,
    classes,
    subRoute,
    cashback,
    cashbackInfo,
    ticker,
    referrals,
    profile,
    cardLimits,
    hasCard,
    isCardsLoading,
    currencies,
    tradingRewards,
    cardRewards,
    referrerConf,
  } = props;

  const referrerConfData = referrerConf?.data || {};
  const bonusCardReferral = referrerConfData?.bonus_card_referral || {};

  const brlCurrency = getCurrencyBySymbol(currencies, "BRL")

  let cashbackData = [];
  let cashbackChartItems = {};
  let cashbackTotal = 0;

  let missionsData = {
    nextMgmLevel: "",
    nextCardLevel: "",
    nextLevelSum: "",
    missingMgm: "",
    missingCard: "",
    hasFirstMonthBonus: false,
  };

  let referralsData = {
    totalEarned: 0,
    totalReferrals: 0,
    transactionReferrals: 0,
    cardReferrals: 0,
    referrals: referrals
  };

  if (cashback.length > 0) {
    cashbackData = cashback.map((item) => {
      return {
        amountInBrl:
          item.currency !== "BRL"
            ? toDecimalPrecision(
              Number(item.total ? item.total : 0) *
              (ticker[item.currency]?.sell
                ? ticker[item.currency].sell
                : 0
              ).toString(),
              8
            )
            : Number(item.total ? item.total : 0),
        ...item,
      };
    });
    cashbackData.forEach((item) => {
      const currency = new Currency(getCurrencyBySymbol(currencies, item.currency) || getCurrencyBySymbol(currencies, "BTC"));
      if (cashbackChartItems[item.currency]) {
        cashbackChartItems[item.currency].value += item.amountInBrl;
      } else {
        cashbackChartItems[item.currency] = {
          title: item.currency,
          value: item.amountInBrl,
          color: currency.color,
        };
      }
    });
      

    cashbackTotal = cashbackData.reduce(
      (acc, curr) => acc + curr.amountInBrl,
      0
    );
  }

  referralsData.totalReferrals = referrals.length;
  referralsData.transactionReferrals = referrals.filter((item) => item.prize1 !== null).length;
  referralsData.cardReferrals = referrals.filter((item) => item.card_target !== null).length;
  referralsData.totalEarned = (!isValidNumber(tradingRewards) || !isValidNumber(cardRewards)) ? '-' : tradingRewards + cardRewards;
  const nextMgmLevel = cashbackInfo ? cashbackInfo.next_mgm_level : null;
  const nextCardLevel = cashbackInfo ? cashbackInfo.next_card_volume_level : null;

  missionsData.nextCardLevel =
    nextCardLevel && nextCardLevel.next_percentage
      ? (nextCardLevel.next_percentage -
        cashbackInfo.cashback_composition.card_volume).toFixed(1)
      : null;
  missionsData.nextMgmLevel =
    nextMgmLevel && nextMgmLevel.next_percentage
      ? (nextMgmLevel.next_percentage - cashbackInfo.cashback_composition.mgm).toFixed(1)
      : null;
  missionsData.nextLevelSum =
    cashbackInfo.isLoading || isCardsLoading
      ? ""
      : missionsData.nextMgmLevel || missionsData.nextCardLevel
        ? (
          Number(missionsData.nextMgmLevel ? missionsData.nextMgmLevel : 0) +
          Number(missionsData.nextCardLevel ? missionsData.nextCardLevel : 0)
        ).toFixed(1)
        : null;
  missionsData.missingCard = nextCardLevel && nextCardLevel.missing_volume ? nextCardLevel.missing_volume : "";
  missionsData.missingMgm = nextMgmLevel && nextMgmLevel.missing_references ? nextMgmLevel.missing_references : "";
  missionsData.hasFirstMonthBonus = cashbackInfo
    ? cashbackInfo.cashback_composition.first_month !== 0
      ? true
      : false
    : false;

  const tableValues = FIREBASE_MGM_INFO.tableTotalValueTrade;

  const defaultTableTotalValueTrade = {
    tier1: '1000',
    tier2: '10000',
    tier3: '30000',
    tier4: '60000',
    tier5: '100000',
  }

  const formattedTableTotalValueTrade = Array.isArray(tableValues) ? Object.fromEntries(tableValues.map((value, index) => [`tier${index + 1}`, value])) : defaultTableTotalValueTrade;

  const userData = {
    cashback: cashbackData,
    cashbackInfo: cashbackInfo,
    ticker: ticker,
    cashbackTotal: cashbackTotal,
    cashbackChartItems: cashbackChartItems,
    referralsData: referralsData,
    profile: profile,
    missionsData: missionsData,
    cardLimits: cardLimits,
    hasCard: hasCard,
    referrerConf: referrerConfData,
    rewards: formattedTableTotalValueTrade
  };


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div className={classes.header}>
        <BannerBitybank
          title={
            <>
              <h2
                dangerouslySetInnerHTML={{
                  __html: t("screens.mgm.indicationProgram"),
                }}
              />
              <Typography
                className="subtitle"
                dangerouslySetInnerHTML={{ __html: t("screens.mgm.benefits", { referrerBonus: formatToBRL(bonusCardReferral.referrer_bonus || '20')}) }}
              />
            </>
          }
          content={
            <Typography
              className="content"
              dangerouslySetInnerHTML={{ __html: t("screens.mgm.downloadApp") }}
            />
          }
        />
      </div>
      <Grid container>
        <Grid justify="center" alignItems="center" item xs={12} md={12}>
          <PageTabs
            tabs={getTabs(t)}
            selectedTab={subRoute}
            onChange={handleChange}
            classes={classes}
          />

          <Route
            path="/mgm/indications"
            render={() => <Indication userData={userData} />}
          />

          <Route
            path="/mgm/cashback"
            render={() => <Cashback userData={userData} />}
          />

          <Route
            path="/mgm/rewards"
            render={() => (
              <Reward userData={userData} brlCurrency={brlCurrency} />
            )}
          />
        </Grid>
      </Grid>
      <div className={classes.footer}>
        <BannerBitybank
          title={
            <>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: t("screens.mgm.downloadAndSeeDetails"),
                }}
              />
              <StoreLinks buttonType={"darkButton"} size={2} showIcon={false} />
            </>
          }
        />
      </div>
    </div>
  );
};

const enhance = compose(withStyles(styles));

export default enhance(MGM);
