import { 
    put, takeLatest, 
} from 'redux-saga/effects'
import { fetchService } from '../../actions';

import { HANDLE_UPDATE_REFERRALS,  FETCH_REFERRALS, HANDLE_REFERRALS_FAIL, FETCH_REFERRER_CONF } from './actionsTypes';
function* fetchReferrals(_action) {
  try {
    yield put(fetchService({
      requestType: 'fetchReferrals',
      successAction: HANDLE_UPDATE_REFERRALS,
      failAction: HANDLE_REFERRALS_FAIL,
      paramns: {
        privateService: true,
        options: {
          cmd: 'get_referrals',
          method: 'POST',
        },
      },
    }));
  } catch (e) {
    console.error('fetchReferrals', e);
  }
}

function* handleReferralsFail(_action) {
  const message = _action?.response?.message_cod;
  if (message === 'NO_REFERRAL_FOUND') {
    yield put({ type: HANDLE_UPDATE_REFERRALS, response: { data: [], card_rewards: 0, trading_rewards: 0 } });
    return;
  }
  console.error('handleReferralsFail', _action);
}

function* fetchReferrerConf(_action) {
  try {
    yield put(fetchService({
      requestType: 'fetchReferrerConf',
      successAction: FETCH_REFERRER_CONF,
      paramns: {
        privateService: true,
        options: {
          cmd: 'get_referrer_conf',
          method: 'POST',
        },
      },
    }));
  } catch (e) {
    console.error('fetchReferrerConf', e);
  }
}

function* watchMgm() {
    yield takeLatest(FETCH_REFERRALS, fetchReferrals);
    yield takeLatest(HANDLE_REFERRALS_FAIL, handleReferralsFail);
    yield takeLatest(FETCH_REFERRER_CONF, fetchReferrerConf);
}
  
export default watchMgm;
  