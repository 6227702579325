import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { translate } from 'react-i18next';
import { DialogContent, DialogActions, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { executeQuote } from '../../redux/actions';
import CloseButton from '@material-ui/icons/CloseOutlined';
import classNames from 'classnames';
import CurrencyIcon from '../../components/CurrencyIcon';
import CurrencyFormat from '../../components/Currency/CurrencyFormat';
import withExpressTradeForm from '../../pages/ExpressTrade/components/ExpressTradeForm/withExpressTradeForm';
import CircleTimer from '../../components/Loaders/CircleTimer';
import { IS_RFQ_ACTIVE } from '../../config';
import { cryptoRoundDown, toDecimalPrecision } from '../../utils/NumberFormat/roundValues';
import { Currency } from '../../models/Currency';
import { formatPercentage } from '../../utils/NumberFormat/percentage';

const styles = theme => ({
    paper: {
      borderRadius: '15px',
      padding: '30px',
      maxWidth: '600px',
      width: '100%',
      '@media  screen and (max-width: 500px)': {
        margin: 'auto 15px',
        padding: '20px',
      },
    },
    dialogTitle:{
      paddingTop: '0px',
      '& .dialogTitleH2': {
        color: '#101010',
        fontSize: '25px',
        marginBottom: '5px',
      }, 
      '@media  screen and (max-width: 500px)': {
        padding: '0px !important',
      },
    },
    closeButton: {
      border: 'none',
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      justifyContent: 'end',
      width: '100%',
      margin: '0',
      padding: '0',
    },
    dialogContent: {
      padding: '0',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontFamily:"'Helvetica','Arial', 'sans-serif'",
      width: '100%',
      
      '@media  screen and (max-width: 500px)': {
        padding: '0px  !important',
      },
      '& .box':{
        width: '90%',
        backgroundColor: '#F8F8F8',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 30px',
        alignItems: 'center',
        marginBottom: '15px',

        '@media  screen and (max-width: 500px)': {
          padding: '20px',
        },
        
        '& .label': {
          color: '#101010',
          fontSize: '15px',
        },
        '& .value': {
          color: '#101010',
          fontSize: '19px',
          fontWeight: '700',
        },
        '& .estimated-quote': {
          textAlign: 'right',
          display: 'flex',
          gap: '15px',
          alignItems: 'center',
        },
        '& .coin': {
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
        },
        '& .quote': {
          color: theme.palette.secondary.main,
          fontSize: '16px',
          fontWeight: '600'
        },
        '& .market': {
          fontWeight: '600',
          '@media  screen and (max-width: 500px)': {
            display: 'none',
          },
        }
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 24px',
      }
    }, 
    icon: {
      minWidth: 50,
      maxWidth: 50,
      height: 50,
      borderRadius: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 4,
      WebkitPaddingStart: 4,
      WebkitPaddingEnd: 4
    },
    span: {
      color: '#101010',
      fontSize: '12px',
      fontWeight: '300 !important',
      marginTop: '4px'
    },
    timer: {
      color: '#858585',
      fontSize: '12px',
      fontWeight: '300 !important',
    },
    dialogActions: {
      flexDirection: 'column-reverse',
      marginTop: '10px',
      gap: '8px',
    },
    button: {
      width:'90%',
      fontSize: '18px',
      textTransform: 'capitalize',
      borderRadius: '10px',
      padding: '12px !important',
      '@media only screen and (max-width: 500px)' : {
        fontSize: '14px',
        margin: 0
      },
    },
    buttonClose: {
      backgroundColor: '#ffffff',
      border: '1px solid rgba(16, 16, 16, 0.3);',
      "&:hover": {
        backgroundColor: '#ffffff'
      },
    },
    buttonConfirm: {
      color: '#fff',
      fontWeight: '400;',
      backgroundColor: '#101010',
      border: '1px solid black',
      "&:hover": {
        backgroundColor: '#000000'
      },
    },
    subtitle: {
      fontSize: '14px'
    }
  });

const ConfirmTransactionExpressDialog = props => {
  const {
    open,
    t,
    handleSubmit,
    classes,
    handleClose,
    orderType,
    remainingTime,
    quoteValues,
    data: {
      totalValue,
      amount,
      price,
      colors,
      duration,
      size,
      strokeWidth,
      keyCounter,
      currencyConfig,
      quoteCurrencyConfig,
    },
  } = props;
  const currency = new Currency(currencyConfig);
  const quoteCurrency = new Currency(quoteCurrencyConfig);
  const showConfirmTransactionSubtitle = orderType === "BUY";
  const labelOrderType = t(`screens.expressTrade.ConfirmTransactionDialog.${orderType.toLowerCase()}`)
  const feePercentage = quoteValues.fee && formatPercentage((Number(quoteValues.fee) * 100).toFixed(2));

  return (
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        classes={{paper: classes.paper}}

      >
        <DialogTitle
          align={'center'}
          id="alert-dialog-slide-title"
          className={classes.dialogTitle}
        >
          <button className={classes.closeButton}>
            <CloseButton className={classes.closeIcon} onClick={handleClose}></CloseButton>
          </button>
          <h2 className={'dialogTitleH2'}>{t('screens.expressTrade.ConfirmTransactionDialog.title', {orderType: labelOrderType})}</h2>
          {showConfirmTransactionSubtitle && <span className={classNames(classes.span, classes.subtitle)}>{t('screens.expressTrade.ConfirmTransactionDialog.subtitle')}</span>}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid className={'box'}>
            <div className={'coin'}>
              <CurrencyIcon classIcon={classes.icon} currency={currency} />
              <div className={'market'}>{currency.symbol}</div>
            </div>
            <div className={'estimated-quote'}>
              <div>
                <CurrencyFormat value={price} isFiat formatter={quoteCurrency._defaultFormatter()} className={'quote'} />
                <div className={classes.span}>{t('screens.expressTrade.ConfirmTransactionDialog.estimatedQuote')}</div>
              </div>
              <div className={classes.timer}>
                <CircleTimer
                  isPlaying={true}
                  duration={duration}
                  colors={colors}
                  size={size}
                  strokeWidth={strokeWidth}
                  keyCounter={keyCounter}
                  initialRemainingTime={remainingTime}
                />
                </div>
            </div>
          </Grid>
          <Grid className={'box'}>
            <div className={'label'}>{t('screens.expressTrade.ConfirmTransactionDialog.total')}</div>
            <CurrencyFormat value={totalValue} isFiat formatter={quoteCurrency._defaultFormatter()} className={'value'} />
          </Grid>
          <Grid className={'box'}>
            <div className={'label'}>{t('screens.expressTrade.ConfirmTransactionDialog.amount', {coin: currency.symbol})}</div>
            <div className={'value'}>{amount}</div>
          </Grid>
          <Grid className={'box'}>
            <div className={'label'}>{t('screens.expressTrade.ConfirmTransactionDialog.fee')}</div>
            <div className={'value'}>{feePercentage}</div>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
           <Button className={classNames(classes.buttonClose, classes.button)} onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <Button  id={`express_confirm_${orderType.toLowerCase()}_button`} className={classNames(classes.buttonConfirm, classes.button)}
           onClick={() => {
            handleSubmit(props);
           }}
          >
           {t('screens.expressTrade.ConfirmTransactionDialog.confirmButton', {orderType: labelOrderType})}
          </Button>
        </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
    return {
        account: state.user.account,
        data: state.layout.transactionsForm.formData,
        quoteValues: state.orders.RFQ,
    };
};

const mapDispatchToProps = dispatch => ({
    executeQuote: (quoteId) => dispatch(executeQuote(quoteId)),
    handleSubmit: (props) => { 
      const {
        orderType,
        createOrder,
        executeQuote,
        handleClose,
        data: {
          market,
          totalValue,
          amount,
          price,
          quoteID,
          resetExpressForm,
        }
      } = props
      if(IS_RFQ_ACTIVE){
        executeQuote(quoteID);
      }else{
        const form = {
          type: orderType,
          amount: cryptoRoundDown(amount),
          price: price,
          volume: toDecimalPrecision(totalValue, 2),
          market: market,
          limited: false
        }
        createOrder(form)
      }
        resetExpressForm();
        handleClose();
    }
});


export default compose(
  withExpressTradeForm,
  translate(),
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ConfirmTransactionExpressDialog)
