import { valueToMd5 } from "./valueToMd5"

export const getRequestHash = (cmd, form) => {
    const newForm = { ...form }
    delete newForm.auth_token
    const formString = JSON.stringify(newForm)
    const hashKey = `${cmd}+${formString}`
    const hashRequest = valueToMd5(hashKey)
    return hashRequest
}
export const openNewTab = (url) => {
    window.open(url, '_blank');
};

export const getMarketOrderSerialized = (rawResponse = {}) => {
    const {
        order_id: id,
        market,
        amount,
        exec_amount,
        price,
        type,
        fee,
        timestamp: concluded,
    } = rawResponse;

    return {
        id: id || '',
        market: market || '',
        amount: amount || 0,
        exec_amount: exec_amount || 0,
        price: price || 0,
        type: type || '',
        status: 'FILLED',
        fee: fee || 0,
        concluded: concluded || '',
        time_stamp: concluded || '',
        limited: '0',
        canceled: '0',
        origin: null,
    };
};