import { connect } from 'react-redux';
import {
  compose, pure, setDisplayName, withHandlers, withState, lifecycle,
  withProps,
} from 'recompose';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import SimpleSchema from 'simpl-schema';
import { EmailSchema } from '../../../utils/Schemas';
import { isMobile } from '../../../utils/checkScreenHelpers';
import {
  addDialog, closeDialog, login, updateCaptcha
} from '../../../redux/actions';

import Login from './LoginForm';
import validateDoc from '../../../components/Forms/asyncValidate';
import { DEV } from '../../../config';

import {RegisterPasswordSchema} from '../../../utils/Schemas';
import { doLogin, loginWithDemoAccount } from './utils';
import {withGoogleReCaptcha} from 'react-google-recaptcha-v3';

const mapDispatchToProps = dispatch => ({
  onSubmit: form => dispatch(login(form)),
  updateCaptcha: token => dispatch(updateCaptcha(token)),
  addFailDialog: (err, d, { message_cod }) => dispatch(
      addDialog({
      title: message_cod,
      availableChoices: [
        {
          label: 'common.close',
          actionToTake: '',
          color: 'secondary',
          variant: 'raised',
        },
      ],
    })),
  addMustReloadDialog: () => dispatch(
    addDialog({
      title: 'RELOAD_PAGE',
      availableChoices: [
        {
          label: 'common.reloadPage',
          actionToTake: 'reloadPage',
          color: 'secondary',
          variant: 'raised',
        },
      ],
      actionsToTake: {
        reloadPage: () => window.location.reload(false)
      }
    })),
    warnAvailableDownloadApp: () => (dispatch(addDialog({
      title: 'APP_AVAILABLE', // eslint-disable-line camelcase
      renderComponent: 'warnAvailableDownloadApp',
      availableChoices: [
        {
          label: 'common.ok',
          actionToTake: 'closeDialog',
          color: 'secondary',
          variant: 'raised',
        },
      ],
      actionsToTake: {
        closeDialog: () => closeDialog()
      }
    })))
});

const mapStateToProps = (state) => {
  return {
    dialog : state.layout.dialog,
    logged: state.credentials.logged
  };
};

const SessionSchema = new SimpleSchema({
  email: EmailSchema,
  captcha: {
    type: String,
    optional: DEV,
  },
  pass: {
    type: String,
    min: RegisterPasswordSchema.min,
    max: RegisterPasswordSchema.max,
  },
});

const validate = values => validateDoc({ values, schema: SessionSchema });

const enhance = compose(
  withGoogleReCaptcha,
  translate(),
  connect(mapStateToProps, mapDispatchToProps),
  withState('recaptchaRef', 'setRecaptchaRef', { reset: () => null }),
  withHandlers({
    onSubmitFail: (props) => (err, d, submitError) => {
      const { addFailDialog, recaptchaRef } = props;
      recaptchaRef.reset();
      const dialogParams = {
        message_cod: submitError && submitError.message_cod || ''
      }
      addFailDialog(err, d, dialogParams);
    },
    onSubmit: (props)=> async (form) => {
      doLogin(props)(form);
    }
  }),
  withProps(({onSubmit}) => {
   return { loginWithDemoAccount: async () => await loginWithDemoAccount(onSubmit) }
  }),
  lifecycle({
    componentDidUpdate(props){
      /**Reset captcha when login fail */
      const {recaptchaRef, dialog} = props;
      if(dialog.title === 'INVALID_EMAIL_OR_PASSWORD'){
          recaptchaRef.reset();
      }
    },
    componentDidMount(){
      const { location, loginWithDemoAccount, warnAvailableDownloadApp, logged } = this.props;
      const shouldLoginAsDemoAccount = location.pathname.includes('/demo')
      if(isMobile() && !logged)
        warnAvailableDownloadApp()
      if( shouldLoginAsDemoAccount ) {
        loginWithDemoAccount();
      }
    }
  }),
  reduxForm({
    form: 'login',
    validate,
  }),
  pure,
  setDisplayName('Login'),
);

export default enhance(Login);
