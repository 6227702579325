import React from "react";
import DangerouslySetInnerHTML from "../../../../../components/DangerouslySetInnerHTML";
import { MonetizationOnOutlined, RecordVoiceOver } from "@material-ui/icons";
import SvgIcon from "../../../../../components/SvgIcon/SvgIcon";
import { ButtonBase, Typography } from "@material-ui/core";
import { fetchReferrerConf } from "../../../../../redux/actions";
import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import classNames from "classnames";
import { FIREBASE_MGM_INFO } from "../../../../../config";
import { formatToBRL } from "../../../../../utils/NumberFormat/moneyFormats";

const AcceptMgmTerms = ({ t, classes, onAccept, referrerConf }) => {
  const {
    data: {
        bonus_card_referral: bonusCardData = {},
        bonus_premium_referral: bonusPremiumData = {}
    } = {}
    } = referrerConf || {};

  let totalPerReferral = Number(FIREBASE_MGM_INFO.totalPerReferralInCurrency) + Number(bonusCardData.user_bonus);
  if (bonusPremiumData?.user_bonus) totalPerReferral += Number(bonusPremiumData.user_bonus);

  const displayReferralAmount = !isNaN(totalPerReferral) ? formatToBRL(totalPerReferral) : '...';
  
    return (
        <div>
        <DangerouslySetInnerHTML
          className={classes.titleDefault}
          title={`${t("screens.mgm.acceptTermsDialog.title")}`}
        />
        <div
          className={classNames(
            classes.flexColumn,
            classes.gapDefault,
            classes.marginTopDefault
          )}
        >
          <RecordVoiceOver classes={{ root: classes.mgmTermsDialogIcon }} />
          <DangerouslySetInnerHTML
            className={classes.boldSubtitle}
            title={`${t("screens.mgm.acceptTermsDialog.phrase1")}`}
          />
        </div>
        <div
          className={classNames(
            classes.flexColumn,
            classes.marginTopDefault,
            classes.gapDefault
          )}
        >
          <MonetizationOnOutlined
            classes={{ root: classes.mgmTermsDialogIcon }}
          />
          <DangerouslySetInnerHTML
            className={classes.boldSubtitle}
            title={`${t("screens.mgm.acceptTermsDialog.phrase2", {
              totalPerReferral: displayReferralAmount,
            })}`}
          />
        </div>
        <div
          className={classNames(
            classes.flexColumn,
            classes.marginTopDefault,
            classes.gapDefault
          )}
        >
          <SvgIcon icon={"MgmIcon"} color={"#F7B733"} />
          <DangerouslySetInnerHTML
            className={classes.boldSubtitle}
            title={`${t("screens.mgm.acceptTermsDialog.phrase3")}`}
          />
        </div>
        <div className={classes.flexContainer}>
          <ButtonBase
            onClick={onAccept}
            className={classNames(
              classes.bitybankButton,
              classes.marginTopDefault
            )}
          >
            {t("screens.mgm.acceptTermsDialog.accept")}
          </ButtonBase>
        </div>
        <Typography
          className={classNames(classes.linkSubtitle, classes.marginTop10)}
        >
          {t("screens.mgm.acceptTermsDialog.disclaimer")}
          <a
            href="https://www.bity.com.br/assets/doc/Regulamento-Indique-e-Ganhe.pdf"
            target={"_blank"}
          >
            {t("screens.mgm.acceptTermsDialog.terms")}
          </a>
        </Typography>
      </div>
    );
};

const mapStateToProps = (state) => ({
    referrerConf: state.user.mgm.referrerConf,
});

const mapDispatchToProps = (dispatch) => ({
    loadReferrerConf: () => dispatch(fetchReferrerConf()),
});

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    lifecycle({
        componentDidMount() {
            const { loadReferrerConf, referrerConf } = this.props;
            if (!referrerConf) loadReferrerConf();
        },
    }),
)

export default enhance(AcceptMgmTerms);