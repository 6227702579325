import {
  put, call, takeEvery, select,
} from 'redux-saga/effects';
import * as R from 'ramda';
import {
  fetchServiceSucceed,
  fetchServiceFailed,
} from './actions';
import beep from '../../../layout/beep';

import { addSessionExpiredDialog } from '../../credentials/actions';
import * as actionTypes from './actionTypes';
import Service from '../../../services/service';
import { ADD_RESPONSE_DIALOG } from '../../layout/dialog/actionTypes';
import { ADD_RESPONSE_SNACK } from '../../layout/snacks/actionTypes';
import { SHOW_SUPPORT_CHAT_API_ERRORS } from "../../../utils/constants"

const optionalTokenServices = ['get_currencies', 'get_markets', 'get_quote'];

const getResponseToUser = responseToUser => {
  switch (responseToUser) {
    case 'dialog':
      return ADD_RESPONSE_DIALOG;

    case 'snack':
      return ADD_RESPONSE_SNACK;

    default:
      return '';
  }
};

function* apiHandler(action) {
  const {
    paramns: { privateService, options, form },
    successAction,
    successActionHasBeep,
    requestType,
    failAction,
    responseToUser: { onSuccess, onFail } = {
      onSuccess: false,
      onFail: false,
    },
  } = action;
  const { credentials, hasSound } = yield select(state => ({
    credentials: state.credentials,
    hasSound: state.layout.appBar.hasSound,
  }));
  const auth_token = R.path(['token'], credentials);
  const demoAccount = R.path(['demoAccount'], credentials);
  const cmd = R.path(['cmd'], options)
  try {
    const serviceForm = privateService && auth_token 
      ? R.merge(form, {
        auth_token,
      })
      : form;

      if (
        demoAccount &&
        privateService &&
        !optionalTokenServices.includes(cmd)
      ) {
        return;
      }

    let response = yield call(Service, options, serviceForm);
    const success = R.propOr(true, 'success', response);

    if ((success !== true && success !== 'success') || !response) {
      throw response;
    }

    yield put(fetchServiceSucceed({ requestType, response }));
    if (successAction) {
      yield put({ type: successAction, response, form });
    }

    if (onSuccess) {
      response["message_cod"] = response["message_cod"] && response["message_cod"].length > 0 ? response["message_cod"] : "GENERIC_SUCCESS";
      if (successActionHasBeep && hasSound) {
        beep();
      }
      yield put({ type: getResponseToUser(onSuccess), response });
    }
  } catch (response) {
    yield put(fetchServiceFailed({ requestType, response }));
    const hasToShowSupportChat = response && response.message_cod && SHOW_SUPPORT_CHAT_API_ERRORS.includes(response.message_cod);
    if(hasToShowSupportChat) {
    response["is_error_to_show_support_chat"] = true;
    }

    if (response) {
      if (failAction) {
        yield put({ type: failAction, response, form });
      }

      if (onFail) {
        response["message_cod"] = response["message_cod"] && response["message_cod"].length > 0 ? response["message_cod"] : "GENERIC_ERROR";
        yield put({ type: getResponseToUser(onFail), response });
      }
      const { message_cod } = response;

      if (message_cod === 'INVALID_TOKEN' && requestType !== 'confirmWithdrawal') {
        if (auth_token) {
          yield put(addSessionExpiredDialog())
        }
      }
    }
  }
}

function* watchServiceData() {
  yield takeEvery(actionTypes.FETCH_SERVICE_REQUESTED, apiHandler);
}

export default watchServiceData;
