import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { translate } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import TransactionButton from './TransactionButton';

const styles = theme => ({
  buyButton: theme.buyButton,
  buyButtonTextPrimary: theme.buyButtonTextPrimary,
});

const enhance = compose(
  // pure,
  translate(),
  withStyles(styles),
);

const BuyButton = props => {
  const { t, classes, symbol = "" } = props;

  return (
    <TransactionButton
      className={classes.buyButton}
      textPrimary={classes.buyButtonTextPrimary}
      to="/buy"
    >
      {t('transactions.buy.action', { tokenName: symbol })}
    </TransactionButton>
  );
};

BuyButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default enhance(BuyButton);
