import React from "react";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { translate } from "react-i18next";
import { reduxForm } from "redux-form";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PasswordField from "../components/Forms/PasswordField";
import PinField from "./Forms/PinField";
import InfoTooltip from "./InfoTooltip";
import { IconButton, ButtonBase, Grid } from "@material-ui/core";
import { LaunchOutlined } from "@material-ui/icons";
import StoreLinks from "./Buttons/StoreLinks";
import { getWindowSize } from "../utils/getWindowSize";
import { useState, useEffect } from "react";
import ActivityIndicator from "../components/ActivityIndicator";
import { OTP_DISABLE_LOW_LEVEL_USERS_URL, OTP_DISABLE_HIGH_LEVEL_USERS_URL } from "../utils/constants";
import { checkIsRobotAccount } from "../utils/accountUtils";

import {
  addDialog,
  addOtpDialog,
  fetchExtract,
  removeCredentials,
  addOtpAppDialog,
  resetOtpRequested
} from "../redux/actions";

import SubmitButton from "./Forms/SubmitButton";
import TextField from "./Forms/TextField";

import { normalize2FA, normalizePIN } from "../utils/NumberFormat/normalizers";

import privateService from "../services/privateService";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import store from "../redux/store";
import { Typography } from "@material-ui/core";
import { openNewTab } from "../utils/generalUtils";

const mapDispatchToProps = (dispatch) => ({
  updateTransactions: () => dispatch(fetchExtract()),
  add2FADialog: (options) => dispatch(addOtpDialog(options)),
  suspiciousDialog: (message_cod) =>
    dispatch(
      addOtpAppDialog({
        title: message_cod,
      })
    ),
  requestResetOtp: (form) => dispatch(resetOtpRequested(form)),
  addFailDialog: (message_cod) =>
    dispatch(
      addDialog({
        title:
          message_cod !== "" && message_cod !== undefined
            ? message_cod
            : "common.genericError",
        renderComponent: "",
        availableChoices: [
          {
            label: "common.close",
            actionToTake: "",
            color: "secondary",
            variant: "raised",
          },
        ],
      })
    ),
  addLogoutDialog: (message_cod) =>
    dispatch(
      addDialog({
        title: message_cod,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        renderComponent: "",
        availableChoices: [
          {
            label: "common.understood",
            actionToTake: "logout",
            color: "secondary",
            variant: "raised",
          },
        ],
        actionsToTake: {
          logout: () => store.dispatch(removeCredentials()),
        },
      })
    ),
});

const styles = (theme) => ({
  link2FA: {
    color: "#ff6900",
    fontSize: "12px",
  },
  disabledLink2FA: {
    color: theme.palette.text.disabled,
    fontSize: "12px",
  },
  resetOtpContainer: {
    gap: "12px"
  },
  storeLinksContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  pinToolTipRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    gap: "2px",

    "& > div": {
      height: "24px",
    },
    "& > a": {
      height: "24px",
      width: "24px",

      "& svg": {
        fontSize: "20px",
      },
    },
  },
  pinInputContainerCriptoWithdrawal: {
    margin: "16px 0 8px",
  },
  pinInputContainerFiatWithdrawal: {
    margin: "8px 0",
  }
});

const validateOtpToken = (token) => {
  return !token || token.length !== 6;
};

const validateConfirmWithdrawalFields = (
  emailToken,
  otpToken,
  password,
  hasPin
) => {
  return (
    !emailToken ||
    !otpToken ||
    !password ||
    emailToken.length !== 6 ||
    otpToken.length !== 6 ||
    (hasPin ? password.length !== 4 : password.length < 8)
  );
};

const validateFiatWithdrawalFields = (pin) => {
  return !pin || pin.length !== 4;
};

const mapStateToProps = (state) => {
  return {
    validateOpt: state.form.validateOpt,
    hasPin: state.user.profile.has_pin,
    loginForm: state.form.login,
    isRequestingResetOtp: state.credentials.isRequestingResetOtp,
    kycLevel: state.user.profile.kyc_level,
    taxId: state.user.profile.tax_id,
  };
};

const validate_otp = async (form, dispatch, props) => {
  const { formValues, customForm } = props;
  const isFiatWithdrawal = props.isWithdrawal && !props.isCryptoWithdrawal;

  const { executeRecaptcha } = props.googleReCaptchaProps;
  const cmd = props.cmd ? props.cmd : "validate_otp";
  const options = {
    cmd,
    method: "POST",
  };

  form = customForm ? { ...form, ...customForm } : form;

  const isCmdLogin = cmd === "login";

  const requestForm = isCmdLogin
    ? { ...formValues, ...form, captcha: await executeRecaptcha(cmd) }
    : form;
  if (props.cmd === "confirm_withdrawal" || isFiatWithdrawal) {
    props.setIsOpen(false);
  }

  if (isFiatWithdrawal) {
    dispatch(
      addDialog({
        title: "common.wait",
        centerTitle: true,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        renderComponent: "withdrawalProgressDialog",
        availableChoices: [],
      })
    );
  }

  const content = await privateService(options, {
    ...requestForm,
    timestamp: moment().format("X"),
  });

  const { success } = content;
  if (success) {
    return content;
  }

  throw content;
};

const handleRequestResetOtp = async (props) => {
  const { kycLevel } = props;
  if (!isNaN(kycLevel)) {
    if (kycLevel < 2) {
      openNewTab(OTP_DISABLE_LOW_LEVEL_USERS_URL);
    } else {
      openNewTab(OTP_DISABLE_HIGH_LEVEL_USERS_URL);
    }
  } else {
    const { executeRecaptcha } = props.googleReCaptchaProps;
    const { requestResetOtp, loginForm } = props;
    const form = loginForm.values;
    const cmd = "get_metamap_id";
    const captcha = await executeRecaptcha(cmd);
    const formValues = { ...form, captcha };
    requestResetOtp(formValues);
  }
};
  

const OtpDialog = (props) => {
  const {
    handleSubmit,
    handleClose,
    open,
    classes,
    t,
    validateOpt,
    otpDialog,
    isRequestingResetOtp,
    taxId,
  } = props;
  const isFiatWithdrawal =
    otpDialog.isWithdrawal && !otpDialog.isCryptoWithdrawal;
  const [width, setWidth] = useState(getWindowSize().width);
  const isRobotAccount = checkIsRobotAccount(taxId);
  const hasPinOrIsNotRobotAccount = !isRobotAccount;

  useEffect(() => {
    const handleResize = () => {
      setWidth(getWindowSize().width);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Dialog
      disableBackdropClick
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">
          {t("forms.otpToken.dialog.title")}
        </DialogTitle>
        <DialogContent>
          {otpDialog.isCryptoWithdrawal && (
            <React.Fragment>
              <DialogContentText>
                {t("forms.otpToken.dialog.pin.content")}
              </DialogContentText>

              <TextField
                normalize={normalizePIN}
                name="email_token"
                i18nScope="forms.emailToken"
              />
            </React.Fragment>
          )}

          {(!isFiatWithdrawal || otpDialog.isCryptoWithdrawal) && (
            <>
              {" "}
              <DialogContentText>
                {t("forms.otpToken.dialog.content")}
              </DialogContentText>
              <TextField
                normalize={normalize2FA}
                name="otp_token"
                i18nScope="forms.otpToken"
              />
            </>
          )}

          {otpDialog.isWithdrawal && (
            <React.Fragment>
              <div className={classes.pinToolTipRow}>
                <DialogContentText>
                  {hasPinOrIsNotRobotAccount
                    ? t("forms.cards.typeYourPin")
                    : t("forms.otpToken.dialog.accessPassword")}
                </DialogContentText>
                {hasPinOrIsNotRobotAccount && (
                  <>
                    <InfoTooltip title={t("forms.cards.pinExplainToolTip")} />
                    <IconButton
                      href={
                        "https://suporte.bity.com.br/pt-BR/articles/8732185-como-resetar-sua-senha-de-4-digitos"
                      }
                      className={classes.knowMoreLink}
                      target={"_blank"}
                    >
                      <LaunchOutlined
                        scale={"primary"}
                        className={classes.infoIcon}
                      />
                    </IconButton>
                  </>
                )}
              </div>
              {hasPinOrIsNotRobotAccount ? (
                <div
                  className={
                    classes[
                      isFiatWithdrawal
                        ? "pinInputContainerFiatWithdrawal"
                        : "pinInputContainerCriptoWithdrawal"
                    ]
                  }
                >
                  <PinField name="pin" required />
                </div>
              ) : (
                <PasswordField
                  required
                  forbidShowPassword
                  autoComplete="current-password"
                  name={"pass"}
                />
              )}
            </React.Fragment>
          )}
          {((otpDialog.isWithdrawal && otpDialog.isCryptoWithdrawal) ||
            !otpDialog.isWithdrawal) && (
            <DialogContentText>
              <Grid
                className={classes.resetOtpContainer}
                direction="row"
                container
                wrap="nowrap"
                alignContent="center"
                alignItems="center"
              >
                <Grid item>
                  <ButtonBase
                    target="_blank"
                    disabled={isRequestingResetOtp}
                    className={isRequestingResetOtp ? classes.disabledLink2FA : classes.link2FA}
                    onClick={() => {
                      handleRequestResetOtp(props);
                    }}
                  >
                    {t("forms.otpToken.dialog.reset")}
                  </ButtonBase>
                </Grid>
                <Grid item>
                  {isRequestingResetOtp && ( <ActivityIndicator size={18}  />)}
                </Grid>
              </Grid>
            </DialogContentText>
          )}
          {isFiatWithdrawal && (
            <>
              <Typography>{t("info.pinIsRequiredToWithdrawal")}</Typography>
              <div className={classes.storeLinksContainer}>
                <StoreLinks
                  width={"100%"}
                  size={2.5}
                  flexDirection={width <= 500 ? "column" : "row"}
                />
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("common.cancel")}
          </Button>
          <SubmitButton
            disabled={
              validateOpt && validateOpt.values
                ? otpDialog.isCryptoWithdrawal
                  ? validateConfirmWithdrawalFields(
                      validateOpt.values.email_token,
                      validateOpt.values.otp_token,
                      hasPinOrIsNotRobotAccount ? validateOpt.values.pin : validateOpt.values.pass,
                      hasPinOrIsNotRobotAccount
                    )
                  : isFiatWithdrawal
                  ? validateFiatWithdrawalFields(validateOpt.values.pin)
                  : validateOtpToken(validateOpt.values.otp_token)
                : true
            }
            submitText={t("forms.otpToken.dialog.validate")}
            isCryptoWithdrawal
            color="primary"
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

const enhance = compose(
  withGoogleReCaptcha,
  translate(),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withHandlers({
    handleClickOpen:
      ({ setIsOpen }) =>
      () =>
        setIsOpen(true),
    handleClose:
      ({ setIsOpen }) =>
      () =>
        setIsOpen(false),
    onSubmitSuccess:
      ({ validateOpt, onOtpValidated, formValues, setIsOpen, form, customForm, extraData}) =>
      async (response) => {

        const otp_token =
          validateOpt.values && validateOpt.values.otp_token
            ? validateOpt.values.otp_token
            : "";
        const externalForm = customForm ? { ...form, ...customForm } : form;
        if (extraData) {
          externalForm.extraData = extraData;
        }
        onOtpValidated({ ...formValues, ...response, otp_token, ...externalForm });
        setIsOpen(false);
      },
    onSubmitFail: (props) => (err, d, response) => {
      let message_cod = response.message_cod;
      const {
        addFailDialog,
        add2FADialog,
        addLogoutDialog,
        token,
        t,
        otpDialog,
        suspiciousDialog,
        updateTransactions,
      } = props;

      if (message_cod === "INVALID_2FA") {
        add2FADialog({
          ...props.otpDialog,
          token,
        });
      } else if (
        message_cod === "AUTHENTICATION_ERROR" &&
        response.type === "OTP"
      ) {
        const errorMessage = t("errors.invalidOtpToken");
        return addFailDialog(errorMessage);
      } else if (message_cod === "WRONG_PASSWORD") {
        const remaining = response.remaining;
        let errorMessage;

        if (remaining <= 4 && remaining > 0) {
          errorMessage = t("WRONG_PASSWORD", { remaining: response.remaining });
        } else {
          errorMessage = "errors.invalidPassword";
        }
        return addFailDialog(errorMessage);
      } else if (
        message_cod === "TOO_MANY_AUTHENTICATION_ERRORS" &&
        otpDialog.isCryptoWithdrawal
      ) {
        return addLogoutDialog(message_cod);
      } else if (
        message_cod === "SUSPICIOUS_WITHDRAWAL_WAITING_OTP" ||
        message_cod === "SUSPICIOUS_WITHDRAWAL_WAITING_LIVENESS" ||
        message_cod === "SUSPICIOUS_WITHDRAWAL_WAITING_FACEMATCH" ||
        message_cod === "SUSPICIOUS_WITHDRAWAL"
      ) {
        updateTransactions();
        return suspiciousDialog(message_cod);
      }
      addFailDialog(message_cod);
    },
  }),
  reduxForm({
    form: "validateOpt",
    onSubmit: validate_otp,
  })
);

export default enhance(OtpDialog);
